import { ApiManager } from '../../managers';
import { Command } from '..';

export async function DispurseLoanCommand({ setIsLoading, params })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    const { accountType, routingNumber, accountNumber, nameOnAccount, loanId, isPayDay } = params;
    console.log('\t\tDispurseLoanCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'loan',
        action: 'dispurse',
        params:
        {
          accountType,
          routingNumber,
          accountNumber,
          nameOnAccount,
          loanId,
          isPayDay,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return null;
      }
      setIsLoading(false);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
