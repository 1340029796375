import React from 'react';
import MaskedInput from '../../maskedInput/maskedInput';

const Input = (props) =>
{
  const {
    onChange,
    onFocus,
    onBlur,
    mask,
    valid,
    focused,
    label,
    className,
    style,
    ...otherProps
  } = props;

  const onChangeHandler = (ev) =>
  {
    ev.persist()
    if (onChange)
    {
      onChange(ev);
    }
  };

  const onFocusHandler = (ev) =>
  {
    ev.persist();
    if (onFocus)
    {
      onFocus(ev);
    }
  };

  const onBlurHandler = (ev) =>
  {
    ev.persist();
    if (onBlur)
    {
      onBlur(ev);
    }
  };

  return (
    <div className={className} style={style}>
      {props.label ? (
        <div mr={2}>
          {props.label}
        </div>
      ) : null}
      <div>
        <MaskedInput
          mask={mask}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          style={{
          }}
          {...otherProps}
        />
      </div>
    </div>
  )
}

export default Input
