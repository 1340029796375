import { ApiManager } from '../../managers';
import { Command } from '..';

export async function QueryDataCommand({ setIsLoading, model, params, dataSetId, dataSetField })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tQueryDataCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'data',
        action: 'query',
        model: model,
        params: params
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      // Save new record in data set
      if(dataSetId)
      {
        const dataSet = dataMgr.getData(dataSetId);
        dataSet[dataSetField] = response.results;
        dataMgr.setData(dataSetId, dataSet);

        // Tell observers data set has updated
        dataMgr.notifyObservers(dataSetId);
      }

      setIsLoading(false);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
