/**
  Add an element to an array in sorted position
  @param  {JSON}    element  Element to be inserted
  @param  {[JSON]}  dataset  Sorted array of elements to be inserted into
  @param  {String}  key      The key in the JSON element to filter on
  @returns  {[JSON]}  Dataset with element inserted in proper locaiton
*/
async function sortedInsert(element, dataset, key)
{
  dataset.splice(locationOf(element, dataset, key) + 1, 0, element);
  return dataset;
}

async function locationOf(element, dataset, key, start, end)
{
  start = start || 0;
  end = end || dataset.length;
  var pivot = parseInt(start + (end - start) / 2, 10);
  if (end - start <= 1 || dataset[pivot][key] === element[key])
  {
    return pivot;
  }
  if (dataset[pivot][key] < element[key])
  {
    return locationOf(element, dataset, key, pivot, end);
  }
  else
  {
    return locationOf(element, dataset, key, start, pivot);
  }
}


/**
  Given a lhs array of objects, a rhs array of objects, and a comparison key.
  Output an array of objects where contained in lhs but not in rhs.
  @param  {Array.<Object>}    lhs  Elements to filter out elements from
  @param  {Array.<Object>}  rhs  Elements we do not want contained in lhs/output
  @param  {String}  key      The key in the JSON element to filter on
  @returns  {[JSON]}  Dataset with objects from lhs that do not exist in rhs.
*/
async function filterObjectsOutOfLhs({ lhs, rhs, key })
{
  const rhsKeys = rhs.map(item => item[key]);

  return lhs.filter(item => rhsKeys.indexOf(item[key]) === -1);
}

let DataModifier =
{
  filterObjectsOutOfLhs: filterObjectsOutOfLhs,
  sortedInsert: sortedInsert
};
export default DataModifier;
