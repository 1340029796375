import { ApiManager } from '../../managers';
import { Command } from '..';

export async function ReferralResponseCommand({ setIsLoading, vendorReferralId, approve, declineReason })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tReferralResponseCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'referral',
        action: 'vendor-response',
        params:
        {
          vendorReferralId: vendorReferralId,
          approve: approve,
          declineReason: declineReason,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return null;
      }
      setIsLoading(false);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
