import React from 'react';
import {
  CWidgetDropdown,
  CRow,
  CCol,
} from '@coreui/react';

const Counters = ({ counters }) =>
{
  return (
    <CRow>
      {counters.map( (counter, i) =>
      {
        return (
          <CCol sm="6" lg="3">
            <CWidgetDropdown
              color={counter.color}
              header={counter.value}
              text={counter.title}
            >
            </CWidgetDropdown>
          </CCol>
        )
      })}
    </CRow>
  )
}

export default Counters;
