import React from 'react';
import Card from 'react-bootstrap/Card'
import {
  CCol,
  CRow,
} from '@coreui/react';
import { AppText } from '../../constant';

const LoanSummary = ({
  price,
  summary,
  chargeText,
  renderPayButton: PayButton,
}) =>
{
  return (

    <Card style={{ padding: '20px' }}>
      <h5>{AppText.pay.orderInfo}</h5>
      {/* Total */}
      <CRow style={{marginBottom: '15px' }}>
        <CCol
          md={6}
          className={'text-left'}
        >
          <div mr={2}>{`Total: `}</div>
        </CCol>
        <CCol
          md={6}
          className={'text-right'}
        >
          <div mr={2}>{`$${price.toFixed(2)}`}</div>
        </CCol>
      </CRow>
      {/* Product */}
      <CRow style={{marginBottom: '15px' }}>
        <CCol
          md={4}
          className={'text-left'}
        >
          <div mr={2}>{`Item: `}</div>
        </CCol>
        <CCol
          md={8}
          className={'text-right'}
        >
          <div mr={2}>{summary}</div>
        </CCol>
      </CRow>
      <hr />
      <div mr={2} style={{marginBottom: '15px', fontWeight: 'bold' }}>
        {chargeText}
      </div>
      <PayButton />
    </Card>
  );
};

export default LoanSummary;
