import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import FormComponent from './formComponent';
import { Billing } from '../billing';
import { AppText } from '../../../constant';
import {
  validateCardCode,
  validateCardNumber,
  validateExpDate
} from '../validation'

const AuthorizeNetScriptUrl =
{
  Production: 'https://js.authorize.net/v1/Accept.js',
  Sandbox: 'https://jstest.authorize.net/v1/Accept.js'
};

export default class AcceptSelfHosted extends React.Component
{
  _formComponentRef = null;

  static runValidations = (formValues) => ({
    cardCode: validateCardCode(formValues.cardCode),
    cardNumber: validateCardNumber(formValues.cardNumber.replace(/\s/g, '')),
    expDate: validateExpDate(formValues.expDate)
  });

  constructor(props)
  {
    super(props)
    this.changeHandler = this.changeHandler.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.focusHandler = this.focusHandler.bind(this);


    this.state = this.props.initialState ||
    {
      apiErrors: [],
      focused: undefined,
      values: { cardNumber: '', cardCode: '', expDate: '' },
      billingInfo:
      {
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        address: '',
      },
      ccInfoValid: false,
      billingInfoValid: false,
    };

    this._formComponentRef = React.createRef();
  }

  componentDidMount()
  {
    const script = document.createElement('script')

    switch (this.props.mode) {
      case 'production':
        script.src = AuthorizeNetScriptUrl.Production
        break
      default:
        script.src = AuthorizeNetScriptUrl.Sandbox
        break
    }
    document.head.appendChild(script);
  }

  componentWillUnmount()
  {
    const head = document.head
    const scripts = head.getElementsByTagName('script');

    Array.from(scripts)
      .filter(
        script =>
          script.src === AuthorizeNetScriptUrl.Production ||
          script.src === AuthorizeNetScriptUrl.Sandbox
      )
      .forEach(injectedScript => head.removeChild(injectedScript));
  }

  dispatchData = async (payload) =>
  {
    console.log(payload);
    return new Promise((resolve, reject) =>
      window.Accept.dispatchData(payload, response =>
      {
        switch (response.messages.resultCode)
        {
          case 'Ok':
            resolve(response)
            break

          case 'Error':
            reject(response)
            break
        }
      })
    );
  }

  // https://developer.authorize.net/api/reference/features/acceptjs.html
  submitProfile = async () =>
  {
    try
    {
      const authData =
      {
        apiLoginID: this.props.apiLoginId,
        clientKey: this.props.clientKey
      };

      const [month, year] = this.state.values.expDate.split('/');

      const cardData = {
        cardNumber: this.state.values.cardNumber.replace(/\s/g, ''),
        cardCode: this.state.values.cardCode,
        month,
        year,
        zip: this.state.billingInfo.zip,
        fullName: `${this.state.billingInfo.firstName} ${this.state.billingInfo.lastName}`,
      };

      const secureData = { authData, cardData };

      const response = await this.dispatchData(secureData);
      if (this.props.onSuccess)
      {
        this.props.onSuccess(response, this.state.values)
      }

      this.setState({
        values: { cardCode: '', cardNumber: '', expDate: '' }
      });

      return response.opaqueData;
    }
    catch(err)
    {
      console.error(err);
      this.setState({
        apiErrors: err.messages ? err.messages.message.map((err: any) => err.text) : err.message
      })
      if (this.props.onError) {
        this.props.onError(err)
      }

      throw err;
    }
  }

  getBillingInfo = () =>
  {
    return this.state.billingInfo;
  }

  changeHandler(field, ev)
  {
    this.setState(oldState => ({
      ...oldState,
      values: {
        ...oldState.values,
        [field]: ev.target.value
      }
    }), () => {
      this._formComponentRef.current.canSubmit();
    });
    return ev;
  }

  focusHandler(field, ev)
  {
    this.setState({ focused: field });
    return ev;
  }

  blurHandler(ev)
  {
    this.setState({ focused: undefined });
    return ev;
  }

  render()
  {
    return (
    <div>
      <FormComponent
        ref={this._formComponentRef}
        amount={this.props.amount}
        apiErrors={this.state.apiErrors}
        onValidChange={(isValid) => {
          this.setState({ ccInfoValid: isValid }, () => {
            console.log('ccInfoValid: ' + this.state.ccInfoValid);
            console.log('billingInfoValid: ' + this.state.billingInfoValid);
            this.props.onValidChange(this.state.ccInfoValid && this.state.billingInfoValid);
          });
        }}
        focused={this.state.focused}
        useExitingProfileOnClick={this.props.useExitingProfileOnClick}
        handleChange={this.changeHandler}
        handleFocus={this.focusHandler}
        handleBlur={this.blurHandler}
        handleSubmit={this.submitHandler}
        validationErrors={AcceptSelfHosted.runValidations(
          R.pick(['cardCode', 'cardNumber', 'expDate'], this.state.values)
        )}
        values={R.pick(
          ['cardCode', 'cardNumber', 'expDate'],
          this.state.values
        )}
      />
      <hr />
      <h5>{AppText.pay.billingAddress}</h5>
      <Billing
        useExistingProfileVisible={this.props.useExistingProfileVisible}
        useExitingProfileOnClick={this.props.useExitingProfileOnClick}
        billingInfo={this.state.billingInfo}
        setBillingProperty={(field, val) => {
          const billingInfo = {...this.state.billingInfo};
          billingInfo[field] = val;
          this.setState({
            billingInfo,
            billingInfoValid: (
              billingInfo.zip &&
              billingInfo.firstName &&
              billingInfo.lastName &&
              billingInfo.state &&
              billingInfo.city &&
              billingInfo.phone
            ) ? true : false,
          }, () => {
            console.log('ccInfoValid: ' + this.state.ccInfoValid);
            console.log('billingInfoValid: ' + this.state.billingInfoValid);
            this.props.onValidChange(this.state.ccInfoValid && this.state.billingInfoValid);
          });
        }}
      />
    </div>
    );
  }
}
