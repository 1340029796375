import ApiRequest from '../api/request.js';
import DataManager from './dataManager';

export default class SiteManager
{
    static singleton = null;
    _dataMgr = null;
    #_components = null;
    #_frontendTitle = "";
    #_pages = null;
    #_pageNames = [];
    #_apiToken = null;
    #_navData = null;

    // Singleton
    /**
      @returns {SiteManager}
     */
    static async GetInstance(cookies = null)
    {
      // Initialize and load styles
      if(SiteManager.singleton == null)
      {
        SiteManager.singleton = new SiteManager();
        await SiteManager.singleton.init(cookies);
      }
      return SiteManager.singleton;
    }

    async init(cookies)
    {
      console.log('\t\tSiteManager.init()');
      this._dataMgr = DataManager.GetInstance();
      this.#_apiToken = cookies.get('token');
  		try
  		{
  			var response = await ApiRequest.sendRequest("post", {}, "site/init", this.#_apiToken);
  			if(response.data.error !== null)
  			{
  				console.error('SiteManager.init error: ' + response.data.error);
          return false;
  			}
        console.log('SiteManager result');
        console.log(response.data);

  			var componentMap = new Map();
        var fields = null;
        var field = null;
        var componentName = ""

        // Build set of {K: Component, V: {fields: Map(), properties: Properties}}
        for(var i = 0; i < response.data.components.length; i++)
        {
          componentName = response.data.components[i].name;
          fields = response.data.components[i].fields;

          if(!componentMap.has(componentName))
          {
            componentMap.set(componentName, {fields: new Map(), properties: response.data.components[i].properties});
          }

          // BUild set of {K: FieldName, V: Field}
          for(var j = 0; j < fields.length; j++)
          {
            field = fields[j];
            var existingFieldSet = componentMap.get(componentName);
            existingFieldSet.fields.set(field.name, field);
            componentMap.set(componentName, existingFieldSet);
          }
        }

        // Build set of {K: PageName, V: PageModel }
        let pageMap = new Map();
        for(var i = 0; i < response.data.pages.length; i++)
        {
          if(!pageMap.has(response.data.pages[i].name))
          {
            pageMap.set(response.data.pages[i].name, response.data.pages[i]);
            this.#_pageNames.push(response.data.pages[i].name);
          }
        }
        this.#_components = componentMap;
        this.#_frontendTitle  = response.data.frontendTitle;
        this.#_pages = pageMap;

        // Store terms of service 
        this._dataMgr.setData('TOS', { tos: response.data.termsOfService });

        return true;
      }
      catch(err)
      {
        console.log('SiteManager.init error: ' + err);
        return false;
      }
    }

    /** Method to retrieve data from backend and store it here as
        Format: map{key: component, value: map{key: fieldName, value: field }}
        Have fields setup to use styled-components and retrieve their colors from this class
      */
    getColorFor = (componentName, fieldName) =>
    {
      if(!this.#_components)
      {
        console.warn("SiteManager.getColorFor(" + componentName + ", " + fieldName + ") was unloadable\n");
        return '#FF0000;';
      }
      else if(this.#_components.get(componentName) === undefined)
      {
        console.warn("SiteManager.getColorFor(" + componentName + ") could not locate component\n");
        return '#FF0000;';
      }
      else if(this.#_components.get(componentName).fields.get(fieldName) === undefined)
      {
        console.warn("SiteManager.getColorFor(" + componentName + ", " + fieldName + ") could not locate field\n");
        return '#FF0000;';
      }
      return this.#_components.get(componentName).fields.get(fieldName).color;
    }

    getComponent = (componentName) =>
    {
      if(!this.#_components)
      {
        console.warn("SiteManager.getComponent(" + componentName + ") was unloadable\n");
        return null;
      }
      else if(this.#_components.get(componentName) === undefined)
      {
        console.warn("SiteManager.getComponent(" + componentName + ") could not locate component\n");
        return null;
      }
      return this.#_components.get(componentName);
    }

    getFrontendTitle = () =>
    {
      return this.#_frontendTitle;
    }

    getPageData = (page) =>
    {
      return this.#_pages.get(page);
    }

    getPageNames = () =>
    {
      return this.#_pageNames;
    }

    setNavData = (navData) =>
    {
      this.#_navData = navData;
    }
    getNavData = () =>
    {
      return this.#_navData;
    }
}
