import React from 'react';
import { CCardHeader } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import StyledComponent from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CsvButton from '../CsvButton';
import DeleteButton from '../DeleteButton';

const Layout2 = ({  selected,
                    title,
                    titleIcon,
                    getTableDataForSelectedRows,
                    tableDidFinishLoading,
                    tableDidStartLoading,
                    isCsvAvailable,
                    cookies,
                    model,
                    isDeleteAvailable,
                    isUpdateAvailable,
                    updateButtonOnClick,
                    isCreateAvailable,
                    createButtonOnClick,
                    isViewAvailable,
                    viewButtonOnClick,
                    customButton1AllowMultiple = false,
                    customButton1,
                    customButton2AllowMultiple = false,
                    customButton2 }) =>
{
  console.log('Title icon: ' + titleIcon);
  return (
    <_css>
      <CCardHeader className={`table-header ${selected.length > 0 ? 'table-header-selected' : ''}`}>

        {/* Title and selected */}
        <div className={'title-container'}>
          {titleIcon && <CIcon name={titleIcon}/>}&nbsp;{`${title}`}&nbsp;
          {selected.length > 0 &&
          <Typography className='table-header-title' color="inherit" variant="subtitle1">
            {selected.length} selected
          </Typography>}
        </div>

        <div>
          {/* CSV button */}
         {selected.length > 0 && isCsvAvailable &&
         <CsvButton csvData={getTableDataForSelectedRows({isForCsv: true})} fileName='csvexport' />}

         {/* Delete button */}
         {selected.length > 0 && isDeleteAvailable &&
         <DeleteButton
            data={selected}
            cookies={cookies}
            tableDidStartLoading={tableDidStartLoading}
            tableDidFinishLoading={(action, message, error) => tableDidFinishLoading(action, message, error)}
            model={model}
         />}

         {/* View button */}
         {selected.length === 1 &&
          isViewAvailable &&
         <Tooltip title="View selected row">
           <IconButton onClick={viewButtonOnClick} aria-label="row">
             <VisibilityIcon />
           </IconButton>
         </Tooltip>}

         {/* Edit button */}
         {selected.length === 1 && isUpdateAvailable &&
           <Tooltip title="Update selected rows">
             <IconButton onClick={updateButtonOnClick} aria-label="row">
               <EditIcon />
             </IconButton>
           </Tooltip>}

          {/* Add button */}
         {selected.length === 0 && isCreateAvailable &&
           <Tooltip title="Create new record">
             <IconButton onClick={createButtonOnClick} aria-label="row">
               <AddIcon />
             </IconButton>
           </Tooltip>}

          {/* Custom button */}
          {(customButton1AllowMultiple
            ? selected.length > 0
            : selected.length === 1) &&
            customButton1 &&
            customButton1({
              data: selected,
              cookies: cookies,
              tableDidStartLoading: tableDidStartLoading,
              tableDidFinishLoading: tableDidFinishLoading
            })
          }

          {/* Custom button */}
          {(customButton2AllowMultiple
            ? selected.length > 0
            : selected.length === 1) &&
            customButton2 &&
            customButton2({
              data: selected,
              cookies: cookies,
              tableDidStartLoading: tableDidStartLoading,
              tableDidFinishLoading: tableDidFinishLoading
            })
          }
        </div>
      </CCardHeader>
    </_css>
  );
}

const _css = StyledComponent.div`
  .table-header-selected
  {
    color: #F50057;
    background-color: #FFE2EC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .table-header
  {
    padding-left: 16px;
    padding-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .table-header-title
  {
  }
  .title-container
  {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
`;

export default Layout2;
