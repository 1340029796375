import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import Layout1 from './layout-1';
import Layout2 from './layout-2';

/**
  *  State:
  *  	@param 	{JSON} 		admin		Contains nav links and icons
  */
export const Admin = ({ appLayout,
                        cookies,
                        selectedNavItem,
                        siteManager,
                        showAlert,
                        updateMasterState,
                        sideNavComponent }) =>
{
  console.log('Selected: ' + selectedNavItem);
  const user = cookies.get('user');
  return(
  <div>
    {!user ? <Redirect to='/login' /> : ''}
    {/*user && (user.authorization.type !== 'admin' && user.authorization.type !== 'customer') ? <Redirect to='/logout' /> : ''*/}
    {appLayout === -1 &&
    <Layout1
      appLayout={appLayout}
      cookies={cookies}
      selectedNavItem={selectedNavItem}
      siteManager={siteManager}
      showAlert={showAlert}
      updateMasterState={updateMasterState}
      sideNavComponent={sideNavComponent}
    />}
    {appLayout === 2 &&
    <Layout2
      appLayout={appLayout}
      cookies={cookies}
      selectedNavItem={selectedNavItem}
      siteManager={siteManager}
      showAlert={showAlert}
      updateMasterState={updateMasterState}
      sideNavComponent={sideNavComponent}
    />}
  </div>);
}

export default Admin;
