import { ApiManager } from '../../managers';
import { Command } from '..';

const DATA_SET_ID = 'loan-payments';
export async function QueryLoanPaymentsCommand({ setIsLoading, loanId, isPayDay })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tQueryLoanPaymentsCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'loan',
        action: 'payments',
        params:
        {
          id: loanId,
          isPayDay: isPayDay,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      // Save new record in data set
      const dataSet = dataMgr.getData(DATA_SET_ID);
      dataSet.receipts = response.results.receipts;
      dataSet.payments = response.results.payments;
      dataSet.receiptDoc = response.results.receiptDoc;
      dataSet.paymentDoc = response.results.paymentDoc;
      dataMgr.setData(DATA_SET_ID, dataSet);

      setIsLoading(false);

      // Tell observers data set has updated
      dataMgr.notifyObservers(DATA_SET_ID);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
