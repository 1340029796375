import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ApiManager } from '../../managers';
import AppText from '../../constant/text';

export default class UserAutocomplete extends React.Component
{
  constructor(props)
  {
    console.log('\tUserAutocomplete()');
    super(props);
    this.state =
    {
      isOpen: false,
      options: [],
      isLoading: false
    };
  }

  async componentDidMount()
  {
    console.log('\tUserAutocomplete.componentDidMount()');
    this._isMounted = true;

    // Fetch data
    this.setState({ isLoading: true });
    const response = await ApiManager.SendRequest(
    {
      route: 'data',
      action: 'query',
      model: 'user',
      params:
      {
        user:
        {
          $ne: this.props.user._id.toString()
        }
      }
    });
    if(response.error !== null)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    this.setState({ options: response.results, isLoading: false });
  }

  onInputChange = (evt, value, reason) =>
  {
    for(let i = 0; i < this.state.options.length; i++)
    {
      if(this.state.options[i].username === value)
      {
        this.props.onChange(this.state.options[i]);
        break;
      }
    }
  }

  render()
  {
    return (
      <Autocomplete
        id="userautocomplete"
        className={'userautocomplete-container'}
        open={this.state.isOpen}
        onInputChange={this.onInputChange}
        onOpen={() => this.setState({ isOpen: true })}
        onClose={() => this.setState({ isOpen: false })}
        getOptionSelected={(option, value) => option._id.toString() === value._id.toString()}
        getOptionLabel={(option) => option.username}
        options={this.state.options}
        loading={this.state.isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={AppText.messages.autocomplete.placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.state.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

}
