import React from 'react';

const Approve = React.lazy(() => import('./pages/approve/approve'));
const Cms = React.lazy(() => import('./pages/cms/cms'));
const Configs = React.lazy(() => import('./pages/configs/configs'));
const Disputes = React.lazy(() => import('./pages/disputes/disputes'));
const EditTos = React.lazy(() => import('./pages/tos/editTos'));
const Emails = React.lazy(() => import('./pages/emails/emails'));
const Friends = React.lazy(() => import('./pages/friends/friends'));
const Home = React.lazy(() => import('./pages/home/home'));
const JobManager = React.lazy(() => import('./pages/jobManager/jobManager'));
const Leads = React.lazy(() => import('./pages/leads/leads'));
const Loans = React.lazy(() => import('./pages/loans/loans'));
const LoanApproval = React.lazy(() => import('./pages/loanApproval/loanApproval'));
const LoanContracts = React.lazy(() => import('./pages/loanContracts/loanContracts'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const Messages = React.lazy(() => import('./pages/messages/messages'));
const ModelManager = React.lazy(() => import('./pages/modelManager/modelManager'));
const NoMatch = React.lazy(() => import('./pages/noMatch/noMatch'));
const PageController = React.lazy(() => import('./components/page/pageController'));
const Pay = React.lazy(() => import('./pages/pay/pay'));
const PaydayLoans = React.lazy(() => import('./pages/payday/payday'));
const Products = React.lazy(() => import('./pages/products/products'));
const Reports = React.lazy(() => import('./pages/reports/reports'));
const Roles = React.lazy(() => import('./pages/roles/roles'));
const Settings = React.lazy(() => import('./pages/settings/settings'));
const Sharing = React.lazy(() => import('./pages/sharing/sharing'));
const Users = React.lazy(() => import('./pages/users/users'));
const VendorApproval = React.lazy(() => import('./pages/vendorApproval/vendorApproval'));
const Vendors = React.lazy(() => import('./pages/vendors/vendors'));
const View = React.lazy(() => import('./pages/view/view'));
const ViewTos = React.lazy(() => import('./pages/tos/viewTos'));

const Routes = [
  /* PageController managed routes */
  {
    path: '/',
    name: '',
    component: PageController,
  },
  {
    path: '/accept-invite',
    name: 'Accept Invite',
    component: PageController,
  },
  {
    path: '/confirm-reset',
    name: 'Confirm Reset',
    component: PageController,
  },
  {
    path: '/login',
    name: 'Login',
    component: PageController,
  },
  {
    path: '/register',
    name: 'Register',
    component: PageController,
  },
  {
    path: '/reset',
    name: 'Reset',
    component: PageController,
  },

  // Not page controller routes
  {
    path: '/approve',
    name: 'Approve',
    component: Approve,
  },
  {
    path: '/cms',
    name: 'CMS',
    component: Cms,
  },
  {
    path: '/configs',
    name: 'Configs',
    component: Configs,
  },
  {
    path: '/disputes',
    name: 'Disputes',
    component: Disputes,
  },
  {
    path: '/edit-tos',
    name: 'EditTos',
    component: EditTos,
  },
  {
    path: '/emails',
    name: 'Emails',
    component: Emails,
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobManager,
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: Leads,
  },
  {
    path: '/loans',
    name: 'Loans',
    component: Loans,
  },
  {
    path: '/loan-approval',
    name: 'Loan Approval',
    component: LoanApproval,
  },
  {
    path: '/loan-contracts',
    name: 'Loan Contracts',
    component: LoanContracts,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: ({ cookies, updateMasterState, siteManager, history }) =>
    {
      return (
        <Logout
          cookies={cookies}
          updateMasterState={updateMasterState}
          siteManager={siteManager}
          history={history}
        />
      )
    },
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
  },
  {
    path: '/model-manager',
    name: 'Model Manager',
    component: ModelManager,
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay,
  },
  {
    path: '/payday',
    name: 'Payday Loans',
    component: PaydayLoans,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/sharing',
    name: 'Sharing',
    component: Sharing,
  },
  {
    path: '/tos',
    name: 'Tos',
    component: ViewTos,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/view',
    name: 'View',
    component: View,
  },
  {
    path: '/vendor-approval',
    name: 'Vendor Approval',
    component: VendorApproval,
  },
  {
    path: '/vendors',
    name: 'Vendors',
    component: Vendors,
  },

  // This must be last
  {
    component: NoMatch,
  },
];

export default Routes;
