import React 		 from 'react';
import DataManager from '../../../components/DataManager/DataManager';

const Layout1 = ({
                    appLayout,
                    cookies,
                    selectedNavItem,
                    siteManager,
                    showAlert,
                    updateMasterState,
                    sideNavComponent
                }) =>
{
  return(
    <div>
      {(selectedNavItem !== -1 || appLayout === 2) &&
      <DataManager
        showModelList={appLayout === 2}
        model={selectedNavItem ? selectedNavItem : ''}
        showAlert={showAlert}
        cookies={cookies}
        siteManager={siteManager}
        title={'Data Manager'}
        titleIcon={'cil-user'}
        select={{
          enabled: true,
          multiSelectEnabled: true,
          selectAllEnabled: true,
        }}
        updateForm={{
          isEnabled: true,
        }}
        createForm={{
          isEnabled: true
        }}
        viewForm={{
          isEnabled: false
        }}
      />}
    </div>
  );
}
export default Layout1;
