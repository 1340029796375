import React from "react";
import PropTypes from "prop-types";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { confirmable, createConfirmation } from "react-confirm";
import { makeStyles } from '@material-ui/core/styles';
import StyledComponent from 'styled-components';

class Confirmation extends React.Component
{
  _css = null;

  // MARK: - Constructor
  constructor(props)
  {
     super(props);

     var state = {};
     this.state = state;

     // Style component
     this._css = this.styleComponent(props.siteManager);

     console.log("Confirmation()");
 }

  render()
  {
    console.log("Confirmation.render()");
    const
    {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true
    } = this.props;

    return (
      <this._css>
        <div className="paper">
          <p>{confirmation}</p>
          <input type='submit'
            onClick={() => proceed(false)}
            value={cancelLabel}
          />
          <input type='submit'
            className="button-l"
            onClick={() => proceed(true)}
            value={proceedLabel}
          />
        </div>
      </this._css>
    );
  }

  // Style component
  styleComponent = (siteManager) =>
  {
    let btnColor = '#e0e0e0';

    //let btnColor = siteManager.getColorFor('Form', 'Submit Button');
    return StyledComponent.div`
      .paper
      {
        background: rgb(255, 255, 255);
        border: 1px solid #000;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
        padding: 8px 16px 12px;
        border-radius: 4px;
        z-index: 1;
      }
      p
      {
        color:      #2F2734;
        text-align: initial;
      }
      input[type=submit]
      {
        color:          rgba(0, 0, 0, 0.87) !important;
        background:     ${btnColor} !important;
        border-color:   ${btnColor} !important;
        padding:        8px 20px;
        border-radius:  4px;
        cursor:         pointer;
        margin-right:   10px;
        border-width:   0.5px;
        border-style:   solid;
        box-shadow:     0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5000;
      position: absolute;
      top: 25%;
      left: 40%;
    `;
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function Confirm(
  confirmation,
  proceedLabel = "Yes",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
