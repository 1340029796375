import { ApiManager } from '../../managers';
import { Command } from '..';

const DATA_SET_ID = 'loanContracts';

export async function QueryLoanContractsCommand({ setIsLoading })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tQueryLoanContractsCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'loan',
        action: 'contracts',
        params: { }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      // Save new record in data set
      const dataSet = dataMgr.getData(DATA_SET_ID);
      dataSet.loanContracts = response.results.loanContracts;
      dataSet.vendorTypes = response.results.vendorTypes;
      dataMgr.setData(DATA_SET_ID, dataSet);

      setIsLoading(false);

      // Tell observers data set has updated
      dataMgr.notifyObservers(DATA_SET_ID);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
