import { parse } from 'query-string';
import React, { useEffect } from 'react';
import * as R from 'ramda';

import {
  CCol,
  CRow,
  CFormText,
  CInput,
} from '@coreui/react';
import Form from 'react-bootstrap/Form'
import { Link }  from 'react-router-dom';
import Row from 'react-bootstrap/Row';

import { Input } from '../../components/authorizeNet';
import { AppText, Images } from '../../constant';

export const ProfileSelect = ({
  paymentProfiles,
  useNewProfileOnClick,
  cvv,
  cvvOnChange,
  selectedProfile,
  selectedProfileOnChange,
}) =>
{
  return (
    <div>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={6}>
          <div mr={2} htmlFor="selectCard">
            {AppText.pay.selectCard}
          </div>
          <Form.Select
            aria-label="Select card"
            id="selectCard"
            value={selectedProfile}
            onChange={e => selectedProfileOnChange(e.target.value) }
          >
            {paymentProfiles &&
            paymentProfiles.map( (profile, i) => {
              return (
                <option
                  key={`profile-${i}`}
                  value={profile.customerPaymentProfileId}
                >{`**** **** **** ${profile.payment.creditCard.cardNumber.substr(-4)}`}</option>)
            })}
          </Form.Select>
        </CCol>
        <CCol md={6}>
          <Input
            name={''}
            type={'text'}
            placeholder={''}
            guide={false}
            label={AppText.pay.cvv}
            mask={[/\d/, /\d/, /\d/, /\d?/]}
            onChange={R.curry(cvvOnChange)('cardCode')}
            valid={true}
            value={cvv}
          />
        </CCol>
      </Row>
      <Row>
        <CCol md={6} />
        <CCol md={6}>
          <a
            className="link"
            onClick={useNewProfileOnClick}
          >
            {'Use a new credit card'}
          </a>
        </CCol>
      </Row>
    </div>
  );
}

export default ProfileSelect
