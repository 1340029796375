import { ApiManager } from '../../managers';
import { Command } from '..';

/**
  If no third party sharing value it will retrieve the value
  Otherwise it updates and returns updated value
*/
export async function ThirdPartySharingCommand({ setIsLoading, userId, thirdPartySharing })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tThirdPartySharingCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'user',
        action: 'third-party-data',
        params:
        {
          id: userId,
          thirdPartySharing: thirdPartySharing,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return null;
      }

      setIsLoading(false);

      return response.result;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
      return null;
    }
  });
}
