import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import MenuItem from '@material-ui/core/MenuItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React 		 from 'react';
import TextField from '@material-ui/core/TextField';
import StyledComponent from 'styled-components';

import { FormLoader }    from '../../components/Form';
import ApiRequest    from '../../api/request.js';
import { Confirm } from "../../components/Confirmation";
import { Datetime } from '../../utils';

import { Redirect }  from 'react-router-dom';

// TODO: Make schema fields read from backend so we can centralize changes to this page
export default class JobManager extends React.Component
{
	// MARK: - Data fields
	_isMounted = false;
  _css = null;
  _loadDataInterval = null;
	_minutes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60];
	_refreshRate = 5000;

  // MARK: - Constructor
	constructor(props)
	{
		super(props);

		this.state =
		{
			jobs: [],
			isLoading: false,
			jobName: 'process-lead-queue',
			queue: 'lead',
			repeatMinutes: 0,
			repeatMidnight: false,
			repeat: false
		};

		this._css = this.styleComponent();
		this._schemaForm = React.createRef();

    console.log('JobManager()');
	}

	componentDidMount()
	{
		console.log('JobManager.componentDidMount()');
		this._isMounted = true;
		this._loadDataInterval = setInterval( () => this.loadData(), this._refreshRate);
	}

	componentWillUnmount()
  {
		console.log('JobManager.componentWillUnmount()');
    clearInterval(this._loadDataInterval);
  }


	// MARK: - APIs
	loadData = async() =>
	{
		console.log('JobManager.loadData()');
		//this.setState({ isLoading: true });

		var params = { params: {} };

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "job/list", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				//this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				//isLoading: false,
				jobs: response.data.results
			});

		}
		catch(err)
		{
			//this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  addJob = async() =>
	{
		console.log('JobManager.addJob()');
		this.setState({ isLoading: true });

		var params =
		{
			jobName: this.state.jobName,
			queue: this.state.queue,
			repeat: this.state.repeat,
			repeatMinutes: this.state.repeatMinutes,
			repeatMidnight: this.state.repeatMidnight,
		};

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "job/start", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

      console.log(response.data);

      const jobs = [...this.state.jobs];
      jobs.unshift(response.data.results);
			this.setState({
				isLoading: false,
				jobs: jobs
			});

		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  clearJobs = async() =>
	{
		console.log('JobManager.clearJobs()');
		this.setState({ isLoading: true });

		var params = { };

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "job/clear", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				isLoading: false,
				jobs: response.data.results
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	cancelJob = async(jobKey) =>
	{
		console.log('JobManager.cancelJob()');
		this.setState({ isLoading: true });

		var params =
		{
			jobKey: jobKey
		};

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "job/cancel", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				isLoading: false,
				jobs: response.data.results
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}


	// MARK: - Render
  render()
  {
    console.log('JobManager.render()');

    return(
			<Paper>
			<this._css>
        <link rel="stylesheet" href="https://www.herokucdn.com/purple3/latest/purple3.min.css" />
				<FormLoader isLoading={this.state.isLoading}/>
	      <Grid container spacing={1} style={{width: '100%'}}>
            <Grid item xs={12}>
							<DialogTitle disableTypography>
		            <Typography
		              variant="h3"
		              classes={{h3: 'friends-title'}}
		              display='block'
		              align={'center'}
		            >{'Batch Job Manager'}</Typography>
		          </DialogTitle>

							{false &&
							<Grid item style={{marginTop: '25px'}}>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
								 	Calculate Total Raised - Iterate all transactions in Classy and calculate the total raised
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Import Members - Import members from Classy and update amounts raised on existing members (Goal amount is updated in Registration Types job)
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Import Teams - Import teams from Classy
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Update Answers - Update the size question answer for all members with missing response
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Update Teams - Fix any member records where team was not imported yet when member was imported. Also update total raised for team.
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Registration Types - Update member's goal amount to honor registration type overrides
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									All - Will run each batch in order of: import members, import teams, update teams, registration types, calculate totals
								</Typography>
							</Grid>}

							<Grid item style={{marginTop: '25px'}}>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
								 	Process Lead Queue - Iterate active lead distribution headers and assign leads to vendors
									Process Subscription Payments - Iterate active subscription receipts and process payments for them
									Process Loan Payments - Iterate active loan receipts and email vendors to make payment
								</Typography>
							</Grid>

							<Grid item style={{marginTop: '25px'}}>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									You cannot schedule two jobs to repeat at the same intervals
								</Typography>
							</Grid>

							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="job-select-label">Job Type</InputLabel>
									<Select
										labelId="job-select-label"
										id="job-select"
										value={this.state.jobName}
										onChange={(e) => {
											this.setState({
												jobName: e.target.value,
												queue: e.currentTarget.dataset.queue,
											});
											console.log(e.currentTarget.dataset);
										}}
									>
										<MenuItem
											value={'process-backup'}
											data-queue={'backup'}
										>Process Backups</MenuItem>
										<MenuItem
											value={'process-lead-queue'}
											data-queue={'lead'}
										>Process Lead Queue</MenuItem>
										<MenuItem
											value={'process-subscription-payments'}
											data-queue={'subscription'}
										>Process Subscription Payments</MenuItem>
										<MenuItem
											value={'process-loan-payments'}
											data-queue={'loan'}
										>Process Loan Payments</MenuItem>
										{false &&
										<>
											<MenuItem value={'calculate-totals'}>Calculate Total Raised</MenuItem>
											<MenuItem value={'members'}>Import Members</MenuItem>
											<MenuItem value={'teams'}>Import Teams</MenuItem>
											<MenuItem value={'update-answers'}>Update Answers</MenuItem>
											<MenuItem value={'update-teams'}>Update Teams</MenuItem>
											<MenuItem value={'tickets'}>Registration Types</MenuItem>
											<MenuItem value={'all'}>All</MenuItem>
										</>}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.repeat}
											onChange={(e) => this.setState({ repeat: e.target.checked })}
											name="repeatCheckBox"
											color="primary"
										/>
									}
									classes={{label: 'checkbox-label'}}
									label="Repeat Every (N) Minutes"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.repeatMidnight}
											onChange={(e) => this.setState({ repeatMidnight: e.target.checked })}
											name="repeatCheckBox"
											color="primary"
										/>
									}
									classes={{label: 'checkbox-label'}}
									label="Repeat At Midnight"
								/>
							</Grid>

							{this.state.repeat &&
							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="repeat-minutes-label">Every (N) Minutes: </InputLabel>
									<Select
										labelId="repeat-minutes-label"
										id="minutes-select"
										value={this.state.repeatMinutes}
										onChange={(e) => this.setState({ repeatMinutes: e.target.value })}
									>
										{this._minutes.map( (minute, i) =>
										{
											return (<MenuItem value={minute}>{minute}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Grid>}

							<Grid item xs={12} style={{marginTop: '25px'}}>
                <Button
				          color="primary"
				          startIcon={<AddIcon />}
									onClick={ () => this.addJob() }
				       	>Add</Button>
                <Button
				          color="primary"
				          startIcon={<ClearIcon />}
									onClick={ () => this.clearJobs() }
				       	>Clear Inactive and Completed Jobs</Button>
              </Grid>
            </Grid>

						{this.state.jobs.map( (job, i) =>
            {
              let bgColor = 'bg-light-purple';
              let progress = job.progress;
              if(job.state === 'completed')
              {
                bgColor = 'bg-purple';
                progress = 100;
              }
              else if(job.state === 'failed')
              {
                bgColor = 'bg-dark-red';
                progress = 100;
              }

							let startedOn = job.startedOn ? Datetime.formatFullDate(new Date(job.startedOn)) : 'Not started yet';
							let finishedOn = job.finishedOn ? Datetime.formatFullDate(new Date(job.finishedOn)) : 'TBD';
							let runtime = job.finishedOn ? Math.abs((new Date(job.finishedOn).getTime() - new Date(job.startedOn).getTime()) / 1000) + ' seconds': '';

              return(
								<Grid item xs={12} style={{marginTop: '25px'}}>
                  <div className="flex justify-between mb2">
                    <div className='mt2 mb1'><span className="hk-label">Job ID:</span>{`${job.data.jobName}-${job.id}`}</div>
                    <div className='mt2 mb1'><span className="hk-label">State:</span>{job.state}</div>
                  </div>
                  <div className="w-100 br1 shadow-inner-1 bg-light-silver">
                    <span className={`db h1 br1 ${bgColor}`} style={{width: `${progress}%`}}></span>
                  </div>
									<div className="flex justify-between mb2">
                    <div className='mt2 mb1'><span className="hk-label">Started On:</span>{`${startedOn}`}</div>
                    <div className='mt2 mb1'><span className="hk-label">End On:</span>{`${finishedOn}`}</div>
										<div className='mt2 mb1'><span className="hk-label">Runtime:</span>{`${runtime}`}</div>
                  </div>
									<div className="flex justify-between mb2">
									{!job.finishedOn &&
									<Button
										color="primary"
										startIcon={<ClearIcon />}
										onClick={ () => this.cancelJob(job.key) }
									>Cancel</Button>}
									</div>
								</Grid>
              )
            })}
	      </Grid>
			</this._css>
			</Paper>
    );
  }


	styleComponent = (siteManager) =>
	{
		return StyledComponent.div`

			min-height: 80%;
			overflow-y: scroll;
			padding: 15px 15px;
			@media(min-width: 600px)
			{
				min-width: 500px;
			}

			.main-btn-section, .bottom-btn-section
			{
				margin-bottom: 25px;
				margin-top: 8px;
				justify-content: space-between;
		    display: flex;
			}
			.bottom-btn-section
			{
				margin-bottom: 0px;
				margin-top: 50px;
				justify-content: space-between;
		    display: flex;
			}
			.schema-name
			{
				width: 100%;
				margin-top: 10px;
			}
			.schema-list
			{
				margin-top: 25px;
			}
			.table-properties-btn
			{
				margin-top: 15px;
			}
			.admin-table-properties
			{
				margin-top: 15px;
				width: 100%;
			}
			.table-properties
			{
				margin-top: 15px;
				width: 100%;
				font-size: 10px;

				textarea
				{
					white-space: pre-line;
				}
			}
			.schema-btns
			{
				margin-bottom: 10px;
			}
			.schema-form
			{
				margin-top: 15px;
			}
			.model-list
			{
				margin-top: 15px;

				h1
				{
					text-align: left;
				}
				h2
				{
					text-align: left;
					color: black;
				}
			}
			.MuiChip-outlinedPrimary
			{
				width: 100%;
				margin-top: 10px;
			}

			.job-description
			{
				color: black;
				text-align: left;
				margin: auto;
			}

			.checkbox-label
			{
				color: #3f51b5;
			}

			.select-control
			{
				width: 100%

				@media(min-width: 600px)
				{
					width: 25%;
				}
			}

			nav
			{
				display: flex !important;
				padding: 16px !important;
			}
		`;
	}
}
