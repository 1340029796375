import React from 'react';

import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavItem,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { Images } from '../../../constant';

// sidebar nav config
const bgNav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/home',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
    /*badge: {
      color: 'info',
      text: 'NEW',
    }*/
  },
  /* Users */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Users']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Users',
    to: '/users',
    icon: 'cil-user',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Roles',
    to: '/roles',
    icon: 'cil-lock-locked',
  },
  /* Vendors */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Vendors']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Vendors',
    to: '/vendors',
    icon: 'cil-paperclip',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Vendor Approval',
    to: '/vendor-approval',
    icon: 'cil-pencil',
  },
  /* Business */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Business']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Products',
    to: '/products',
    icon: 'cil-map',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Opportunities',
    to: '/opportunities',
    icon: 'cil-chart-pie',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Disputes',
    to: '/disputes',
    icon: 'cil-graph',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Payday Loans',
    to: '/payday',
    icon: 'cil-dollar',
  },
  /* CMS */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['CMS']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Forms',
    to: '/cms',
    icon: 'cil-spreadsheet',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Terms and conditions',
    to: '/edit-tos',
    icon: 'cil-notes',
  },

  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
];

const dfNav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/home',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
    /*badge: {
      color: 'info',
      text: 'NEW',
    }*/
  },
  /* Users */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Users']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Users',
    to: '/users',
    icon: 'cil-user',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Roles',
    to: '/roles',
    icon: 'cil-lock-locked',
  },
  /* Loans */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Loans']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Advance Loans',
    to: '/loans',
    icon: 'cil-dollar',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Loan Approval',
    to: '/loan-approval',
    icon: 'cil-pencil',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Loan Contracts',
    to: '/loan-contracts',
    icon: 'cil-map',
  },
  /* Business */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Business']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Opportunities',
    to: '/opportunities',
    icon: 'cil-chart-pie',
  },
  /* CMS */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['CMS']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Forms',
    to: '/cms',
    icon: 'cil-spreadsheet',
  },

  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
];


const Layout2 = ({  user,
                    name,
                    navOnChange,
                    isShowing,
                    active,
                    setIsShowing,
                    activeApp }) =>
{
  return (
    <CSidebar
      show={isShowing}
      onShowChange={(val) => setIsShowing(val)}
    >
      <CSidebarBrand className="d-md-down-none" to="/home">
        <img
          className="c-sidebar-brand-full"
          style={{height: '35px'}}
          src={Images[activeApp + 'Icon']}
        />
        <img
          className="c-sidebar-brand-minimized"
          style={{height: '35px'}}
          src={Images[activeApp + 'IconSmall']}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={activeApp === 'bg' ? bgNav : dfNav}
          components={{
            CSidebarNavDivider,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default Layout2;
