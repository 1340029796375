import React from 'react';

import { Table } from '../../Table';

const ActivityFeed = ({ activityFeed }) =>
{
  return (
    <div className={'data-manager-large-table'} style={{ marginBottom: '25px' }}>
      <Table
        data={activityFeed.data}
        headers={activityFeed.modelDoc.tableProperties.headers}
        model={activityFeed.model}
        schemaFields={activityFeed.modelDoc.schemaFields}

        selectEnabled={false}
        selectAllEnabled={false}
        multiSelectEnabled={false}

        defaultSortOrder={activityFeed.defaultSortOrder}
        defaultSort={activityFeed.defaultSort}
        sortEnabled={true}

        tableDidStartLoading={(action) => console.log(action)}
        tableDidFinishLoading={(action, message, error) => console.log(action)}

        title={activityFeed.title}
        titleIcon={'cil-notes'}

        isDeleteAvailable={false}

        updateForm={{
          isEnabled: false
        }}
        updateFormInputs={[]}

        isCreateAvailable={false}
        createFormInputs={[]}
        createForm={{}}

        viewForm={{}}

        isCsvAvailable={true}

        paginationEnabled={true}

        cookies={null}
        siteManager={null}

        layout={2}
      />
    </div>
  );
}

export default ActivityFeed;
