import React from 'react';
import StyledComponent from 'styled-components';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Helmet: Dynamic page title
import { Helmet } from 'react-helmet';

import { Emails, Friends, Home, JobManager, Messages, ModelManager, NoMatch, Reports, Settings, View } from '../pages';
import { NavList, NavigationBar } from '../components/nav';
import {PageController} from '../components/page';
import NotificationAudio from '../asset/notification.mp3';
import { Footer } from '../components/footer';

const Layout1 = ({  appLayout,
                    title,
                    siteManager,
                    selectedNavItem,
                    showTitleInNav,
                    showAlert,
                    token,
                    notificationManager,
                    path,
                    updateMasterState,
                    forward,
                    cookies,
                    isMobile,
                    pushManager,
                    reloadPageData,
                    redirect,
                    pageComponents,
                    alertDismissible,
                    components,
                    notificationAudioRef
                }) =>
{
  return (
    <_css>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Router>
        <div className={'app-wrapper'}>
          {siteManager &&
          <NavigationBar
            title={title}
            selectedNavItem={selectedNavItem}
            showTitle={showTitleInNav}
            showAlert={showAlert}
            loggedIn={token !== undefined}
            notificationManager={notificationManager}
            siteManager={siteManager}
            path={path.replace('/', '')}
            updateMasterState={updateMasterState}
            forward={forward}
            cookies={cookies}
            isMobile={isMobile}
          />}
          {alertDismissible}
          <div className="pane-holder">
            {!isMobile &&
              token &&
            <div className="left-pane">
              {siteManager &&
              <NavList
                layout={appLayout}
                active={selectedNavItem}
                siteManager={siteManager}
                user={cookies.get('user')}
                showAlert={showAlert}
                updateMasterState={updateMasterState}
              />}
            </div>}
            <div className={`center-pane center-pane-${cookies.get('token') ? '80' : '100'}`}>
              <Switch>
                <Route exact path="/" render={(props) =>
                  <PageController {...props}
                    showAlert={showAlert}
                    path={path.replace('/', '')}
                    updateMasterState={updateMasterState}
                    cookies={cookies}
                    reloadPageData={reloadPageData}
                    siteManager={siteManager}
                    pushManager={pushManager}
                    notificationManager={notificationManager}
                    pageComponents={pageComponents !== null ? [...pageComponents.get('login')] : []}
                  />}
                />
                <Route path="/login" render={(props) =>
                  <PageController {...props}
                    showAlert={showAlert}
                    path={path.replace('/', '')}
                    updateMasterState={updateMasterState}
                    cookies={cookies}
                    reloadPageData={reloadPageData}
                    siteManager={siteManager}
                    pushManager={pushManager}
                    notificationManager={notificationManager}
                    pageComponents={pageComponents !== null ? [...pageComponents.get('login')] : []}
                  />}
                />
                <Route path="/model-manager" render={(props) =>
                  <ModelManager {...props}
                    showAlert={showAlert}
                    cookies={cookies}
                    siteManager={siteManager}
                  />}
                />
                <Route path="/jobs" render={(props) =>
                  <JobManager {...props}
                    showAlert={showAlert}
                    cookies={cookies}
                    siteManager={siteManager}
                  />}
                />
                <Route path="/reports" render={(props) =>
                  this.state.apiManager &&
                  <Reports {...props}
                    showAlert={showAlert}
                    cookies={cookies}
                    siteManager={siteManager}
                  />}
                />
                <Route path="/view" render={(props) =>
                  notificationManager &&
                  <View {...props}
                    showAlert={showAlert}
                    cookies={cookies}
                    notificationManager={notificationManager}
                    siteManager={siteManager}
                    pushManager={pushManager}
                    updateMasterState={updateMasterState}
                    isMobile={isMobile}
                  />}
                />
                <Route path="/messages" render={(props) =>
                  siteManager &&
                  <Messages {...props}
                    cookies={cookies}
                    showAlert={showAlert}
                    siteManager={siteManager}
                    updateMasterState={updateMasterState}
                  />}
                />
                <Route path="/settings" render={(props) =>
                  siteManager &&
                  <Settings
                    updateMasterState={updateMasterState}
                    cookies={cookies}
                    pushManager={pushManager}
                    siteManager={siteManager}
                    showAlert={showAlert}
                  />}
                />
                <Route path="/friends" render={(props) =>
                  siteManager &&
                  <Friends
                    cookies={cookies}
                    showAlert={showAlert}
                    siteManager={siteManager}
                    updateMasterState={updateMasterState}
                  />}
                />
                <Route path="/emails" render={(props) =>
                  siteManager &&
                  <Emails
                    cookies={cookies}
                    showAlert={showAlert}
                    siteManager={siteManager}
                    updateMasterState={updateMasterState}
                  />}
                />
                <Route path="/register" render={(props) =>
                  <PageController {...props}
                    showAlert={showAlert}
                    path={path.replace('/', '')}
                    updateMasterState={updateMasterState}
                    cookies={cookies}
                    reloadPageData={reloadPageData}
                    siteManager={siteManager}
                    pushManager={pushManager}
                    notificationManager={notificationManager}
                    pageComponents={pageComponents !== null ? [...pageComponents.get('register')] : []}
                  />}
                />
                <Route path="/home" render={(props) =>
                <>
                  {pageComponents &&
                  <Home {...props}
                    appLayout={appLayout}
                    showAlert={showAlert}
                    selectedNavItem={selectedNavItem}
                    cookies={cookies}
                    siteManager={siteManager}
                    pageComponents={components}
                    pushManager={pushManager}
                    updateMasterState={updateMasterState}
                    isMobile={isMobile}
                  />}
                  {!pageComponents && <></>}
                  </>
                }
                />
                <Route path="/logout" render={(props) =>
                {
                  cookies.remove('user');
                  cookies.remove('token');
                  this.setState({ title: siteManager ? siteManager.getFrontendTitle() : '' }, () =>
                  {
                    props.history.push('/login');
                  });
                }}
                />
                <Route path="/reset" render={(props) =>
                  <PageController {...props}
                    showAlert={showAlert}
                    path={path.replace('/', '')}
                    updateMasterState={updateMasterState}
                    cookies={cookies}
                    reloadPageData={reloadPageData}
                    siteManager={siteManager}
                    pushManager={pushManager}
                    notificationManager={notificationManager}
                    pageComponents={pageComponents !== null ? [...pageComponents.get('reset')] : []}
                  />}
                />
                <Route path="/confirm-reset" render={(props) =>
                  <PageController {...props}
                    showAlert={showAlert}
                    path={path.replace('/', '')}
                    updateMasterState={updateMasterState}
                    cookies={cookies}
                    reloadPageData={reloadPageData}
                    siteManager={siteManager}
                    pushManager={pushManager}
                    notificationManager={notificationManager}
                    pageComponents={pageComponents !== null ? [...pageComponents.get('confirm-reset')] : []}
                  />}
                />
                <Route component={NoMatch} />
              </Switch>
            </div>
          </div>
          {redirect &&
          <Redirect to={{ pathname: redirect }} />}
        </div>
      </Router>
      <Footer
        copyrightText={'Copyright 2020 Check In'}
        siteManager={siteManager}
      />
      <audio
        ref={notificationAudioRef}
        autoPlay={true}
        muted={"muted"}
        src={NotificationAudio}
      />
    </_css>
  );
}

const _css = StyledComponent.div`
  .app-wrapper
  {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    background: #F5F5F5;
  }

  .pane-holder
  {
      margin-top: 0px;
      max-width:  100%;
      margin-top: 10px;
      display:    flex;
      flex-direction: row;
      padding-bottom: 80px;
      @media only screen and (max-width: 992px)
      {
        flex-direction: column;
      }
  }
  .left-pane
  {
      text-align: left;
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 993px)
      {
        width: 15%;
        float: left;
        margin-left: 1%;
      }
      @media only screen and (max-width: 992px)
      {
        width: 100%;
      }
  }

  .center-pane
  {
      display:flex;
      margin-left: 2%;
      margin-right: 2%;
      flex-direction:column;
      float: right;
      border-radius: 4px;

      @media only screen and (max-width: 992px) and (min-width: 601px)
      {
        margin-top: 2%;
        margin-left: auto;
        margin-right: auto;
      }
      @media only screen and (max-width: 600px)
      {
        width:100% !important;
        margin-left: 0%;
        margin-right: 0%;
      }
  }
  .center-pane-80
  {
    width: 80%;
  }
  .center-pane-100
  {
    width: 100%;
  }

  .full-width
  {
    width: 100% !important;
  }

  text-align:         center;
  background-color:   #FFFFFF;
  width:              100%;
  position:           absolute !important;
  top:                0px;
  bottom:             0;

  @media only screen and (max-width: 992px)
  {
    position: relative;
  }
`;

export default Layout1;
