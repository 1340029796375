import React from 'react';
import StyledComponent from 'styled-components';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import Qr from 'qrcode';

import { AppText, Colors } from '../../constant';
import { ApiManager }  from '../../managers';
import { NotificationUtility } from '../../components/notifications';
import { FormValidated } from '../../components/Form';
import {schemaFieldToFormInput} from '../../components/Form/utility';
import { FormInput } from '../../components/Form';

export default class Reports extends React.Component
{
  _isMounted = false;
	_css = null;
  _createForm = null;

  // MARK: - Constructor
	constructor(props)
	{
		console.log('Reports()');
		super(props);
    this.state =
    {
      isLoading: false,
      report: null,
      event: null,
      events: [],
    };

    this._createForm = React.createRef();

    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('Reports.componentDidMount()');
    this._isMounted = true;
    this.loadData();
  }


  // MARK: - API
	loadData = async () =>
	{
		try
		{
			console.log('Reports.loadData()');
			this.setState({ isLoading: true });
			const response = await ApiManager.SendRequest(
			{
				route: 'classy',
				action: 'report',
        params:
        {
          event: this.state.event
        }
			});

      console.log(response);

			if(response.error !== null)
			{
				this.setState({ isLoading: false  });
				this.props.showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}

			this.setState({
				isLoading: false,
        report: response.results,
        events: response.events,
        event: response.activeEvent,
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  render()
	{
    const userId = this.props.cookies.get('user')._id.toString();


    //console.log(this.state.friends);
    console.log('Reports.render()');
    return (
      <Paper>
        <this._css>
          <DialogTitle disableTypography>
            <Typography
              variant="h3"
              classes={{h3: 'friends-title'}}
              display='block'
              align={'center'}
            >{AppText.reports.title}</Typography>
          </DialogTitle>
          <DialogContent /*dividers*/ className='friends-content'>

                <Grid container direction="row">
                  <Grid item xs={6}>
                    {this.state.report &&
                    Object.keys(this.state.report).map( (reportKey) =>
                    {
                      return (
                        <Grid container direction="row" style={{marginTop: '25px'}, {marginBottom: '25px'}}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              classes={{h5: 'preview-title'}}
                              display='block'
                              align={'left'}
                            >{this.state.report[reportKey].description}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body2"
                              classes={{body2: 'info-message'}}
                              align={'left'}
                            >{this.state.report[reportKey].value}</Typography>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={'select-control'}>
                      <InputLabel className={'select-label'} id="job-select-label">Event</InputLabel>
                      <Select
                        labelId="job-select-label"
                        id="job-select"
                        value={this.state.event ? this.state.event._id.toString() : ''}
                        onChange={(e) =>
                        {
                          let theEvent = null;
                          for(let i = 0; i < this.state.events.length; i++)
                          {
                            if(this.state.events[i]._id.toString() === e.target.value)
                            {
                              theEvent = this.state.events[i];
                              break;
                            }
                          }

                          this.setState({ event: theEvent }, () => this.loadData() )
                        }}
                      >
                        {this.state.events.map( (event, i) =>
                        {
                          return (
                            <MenuItem value={event._id.toString()}>{event.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
          </DialogContent>
        </this._css>
      </Paper>
    );
  }

  styleComponent = (siteManager) =>
	{
    var bgColor = '#222';
    var textColor = '#FFF';
    if(siteManager !== null && siteManager !== undefined)
    {
      //console.log(props.siteManager);
      bgColor = siteManager.getColorFor('Header', 'Background');
      textColor = siteManager.getColorFor('Header', 'Text');
    }

		return StyledComponent.div`
      min-height: 80%;
      overflow-y: scroll;
      padding: 15px 15px;

      .friends-content
      {
        text-align: left;
      }
      .friends-title
      {
        color: ${bgColor};
      }
      .preview-title
      {
        color: ${bgColor};
      }
      .create-form
      {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .select-control
			{
				width: 100%

				@media(min-width: 600px)
				{
					width: 55%;
				}
			}
      .select-label
      {
        width: 100%

				@media(min-width: 600px)
				{
					width: 55%;
				}
      }
      .selector
      {
        width: 100%

				@media(min-width: 600px)
				{
					width: 55%;
				}
      }
      .main-btn-section
      {
        align-self: flex-end;
      }
      .bottom-section
      {
        flex-direction: row;
      }
      .info-message
      {
       white-space:pre-wrap;
      }
		`;
	}
}
