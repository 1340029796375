import React from 'react';
import StyledComponent from 'styled-components';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import Qr from 'qrcode';

import { AppText, Colors } from '../../constant';
import { ApiManager }  from '../../managers';
import { NotificationUtility } from '../../components/notifications';
import { Form } from '../../components/Form';
import {schemaFieldToFormInput} from '../../components/Form/utility';
import { FormInput } from '../../components/Form';

export default class Emails extends React.Component
{
  _isMounted = false;
	_css = null;
  _createForm = null;

  // MARK: - Constructor
	constructor(props)
	{
		console.log('Emails()');
		super(props);
    this.state =
    {
      isLoading: false,
      templates: [],
      createInputs: [],
      template: -1,
      testMember: null,
      testEvent: null,
      awsTemplate: null,
      toEmail: '',

      subject: '',
      title: '',
      headerLogoUrl: '',
      firstLine: '',
      secondLine: '',
      thirdLine: '',
      fourthLine: '',
      footerText: '',
      facebookUrl: '',
      twitterUrl: '',
      instagramUrl: '',
      linkedInUrl: '',
      youtubeUrl: '',
      headerLogoLink: '',
    };

    this._createForm = React.createRef();

    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('Emails.componentDidMount()');
    this._isMounted = true;
    this.loadData();
  }


  // MARK: - API
	loadData = async () =>
	{
		try
		{
      // Load email template schema fields
      // Load email template records


			console.log('Emails.loadData()');
			this.setState({ isLoading: true });
			const response = await ApiManager.SendRequest(
			{
				route: 'email',
				action: 'load'
			});
			if(response.error !== null)
			{
				this.setState({ isLoading: false  });
				this.props.showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}

      console.log(response);

      let createInputs = [];
      let formInput = null;
      for(let i = 0; i < response.results.schemaFields.length; i++)
      {
        if(response.results.schemaFields[i].name !== 'createdBy')
        {
          createInputs.push(await schemaFieldToFormInput(response.results.schemaFields[i], this.props.cookies.get('token')));
        }
      }

			this.setState({
				isLoading: false,
				templates: response.results.templates,
        createInputs: createInputs,
        awsTemplate: response.results.awsTemplate,
        testMember: response.results.testMember,
        testEvent: response.results.testEvent
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  createTemplate = async () =>
  {
    try
    {
      console.log('Emails.createTemplate()');
      this.setState({ isLoading: true });

      let params = {...this.state};
      delete params.isLoading;
      delete params.templates;
      delete params.createInputs;
      delete params.template;
      delete params.testMember;
      delete params.testEvent;
      delete params.awsTemplate;
      delete params.toEmail;

      const response = await ApiManager.SendRequest(
      {
        route: 'data',
        action: 'create',
        model: 'emailtemplate',
        params: params
      });
      if(response.error !== null)
      {
        this.setState({ isLoading: false  });
        this.props.showAlert(true, 'Un-oh', response.error, 'danger');
        return;
      }

      console.log(response.results);

      let createInputs = [];
      let formInput = null;
      for(let i = 0; i < this.state.createInputs.length; i++)
      {
        let input = this.state.createInputs[i];
        input.value = '';
        createInputs.push(input);
      }

      let templates = [...this.state.templates];
      templates.push(response.results);

      this.setState({
        isLoading: false,
        templates: templates,
        createInputs: createInputs
      });

      this.props.showAlert(true, 'Success', 'Template created', 'success');
      this._createForm.current.reset();
    }
    catch(err)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
    }
  }

  updateTemplate = async () =>
  {
    try
    {
      console.log('Emails.updateTemplate()');
      this.setState({ isLoading: true });

      let params = {...this.state};
      delete params.isLoading;
      delete params.templates;
      delete params.createInputs;
      delete params.template;
      delete params.testMember;
      delete params.testEvent;
      delete params.awsTemplate;
      delete params.toEmail;

      const response = await ApiManager.SendRequest(
      {
        route: 'data',
        action: 'update',
        model: 'emailtemplate',
        id: this.state.templates[this.state.template]._id.toString(),
        params: params
      });
      if(response.error !== null)
      {
        this.setState({ isLoading: false  });
        this.props.showAlert(true, 'Un-oh', response.error, 'danger');
        return;
      }

      console.log(response.results);

      let createInputs = [];
      let formInput = null;
      for(let i = 0; i < this.state.createInputs.length; i++)
      {
        let input = this.state.createInputs[i];
        input.value = '';
        createInputs.push(input);
      }

      let templates = [...this.state.templates];
      templates[this.state.template] = response.results;

      this.setState({
        isLoading: false,
        templates: templates,
        createInputs: createInputs,
        template: -1,
      });

      this.props.showAlert(true, 'Success', 'Template updated', 'success');
      this._createForm.current.reset();
    }
    catch(err)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
    }
  }

  deleteTemplate = async () =>
  {
    try
    {
      console.log('Emails.updateTemplate()');
      this.setState({ isLoading: true });
      const response = await ApiManager.SendRequest(
      {
        route: 'data',
        action: 'delete',
        model: 'emailtemplate',
        id: this.state.templates[this.state.template]._id.toString()
      });
      if(response.error !== null)
      {
        this.setState({ isLoading: false  });
        this.props.showAlert(true, 'Un-oh', response.error, 'danger');
        return;
      }

      let templates = [...this.state.templates];
      templates = templates.splice(this.state.template, 1);

      let createInputs = [];
      let formInput = null;
      for(let i = 0; i < this.state.createInputs.length; i++)
      {
        let input = this.state.createInputs[i];
        input.value = '';
        createInputs.push(input);
      }

      this.setState({
        isLoading: false,
        templates: templates,
        createInputs: createInputs,
        template: -1,
      });

      this.props.showAlert(true, 'Success', 'Template deleted', 'success');
      this._createForm.current.reset();
    }
    catch(err)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
    }
  }

  parse = (fieldName) =>
  {
    console.log(this.state.createInputs);
    let input = this.state.createInputs.filter(input => input.id === fieldName);
    console.log(input);
    if(input.length > 0)
    {
      input = input[0];
    }
    else
    {
      return '';
    }
    let parsedString = input.value;
    let replaceIdxs = this.getIndicesOf('{{', input.value);
    let closingIdx = -1;
    let classVal = '';
    let propVal = '';
    let replaceVal = '';

    for(let i = 0; i < replaceIdxs.length; i++)
    {
      closingIdx = input.value.indexOf('}}', replaceIdxs[i]);
      if(closingIdx !== -1)
      {
        replaceVal = input.value.substr(replaceIdxs[i] + 2, closingIdx - (2 + replaceIdxs[i]));
        classVal = replaceVal.substr(0, replaceVal.indexOf('.'));
        propVal = replaceVal.substr(replaceVal.indexOf('.') + 1);

        if(classVal.toLowerCase() === 'member')
        {
          parsedString = parsedString.replace('{{' + replaceVal + '}}', this.state.testMember[propVal]);
          console.log(parsedString);
        }
        else if(classVal.toLowerCase() === 'event')
        {
          parsedString = parsedString.replace('{{' + replaceVal + '}}', this.state.testEvent[propVal]);
        }
      }
    }

    return parsedString;
  }

  getIndicesOf = (searchStr, str, caseSensitive) =>
  {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    var startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
  }

  renderTemplate = async() =>
  {
    try
    {
      console.log('Emails.renderTemplate()');
      this.setState({ isLoading: true });


      const response = await ApiManager.SendRequest(
      {
        route: 'email',
        action: 'sendTemplate',
        params:
        {
          templateData:
          {
            SUBJECT: this.parse('subject'),
            TITLE: this.parse('title'),
            HEADER_LOGO_URL: this.parse('headerLogoUrl'),
            FIRST_LINE: this.parse('firstLine'),
            SECOND_LINE: this.parse('secondLine'),
            THIRD_LINE: this.parse('thirdLine'),
            FOURTH_LINE: this.parse('fourthLine'),
            FOOTER_TEXT: this.parse('footerText'),
            TWITTER_URL: this.parse('twitterUrl'),
            FACEBOOK_URL: this.parse('facebookUrl'),
            INSTAGRAM_URL: this.parse('instagramUrl'),
            LINKEDIN_URL: this.parse('linkedInUrl'),
            YOUTUBE_URL: this.parse('youtubeUrl'),
            HEADER_LOGO_LINK: this.parse('headerLogoLink')
          },
          toEmail: this.state.toEmail,
          payload: this.state.testMember.fundraisingPageID
        }
      });

      console.log(response);
      if(response.error !== null)
      {
        this.setState({ isLoading: false  });
        this.props.showAlert(true, 'Un-oh', response.error, 'danger');
        return;
      }

      console.log(response.results);

      this.setState({
        isLoading: false,
      });

      this.props.showAlert(true, 'Success', 'Email sent', 'success');
    }
    catch(err)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
    }
  }

  render()
	{
    const userId = this.props.cookies.get('user')._id.toString();

    //console.log(this.state.friends);
    console.log('Emails.render()');
    return (
      <Paper>
        <this._css>
          <DialogTitle disableTypography>
            <Typography
              variant="h3"
              classes={{h3: 'friends-title'}}
              display='block'
              align={'center'}
            >{AppText.emails.title}</Typography>
          </DialogTitle>
          <DialogContent /*dividers*/ className='friends-content'>
            <FormControl component="fieldset">
              <FormGroup
                aria-label="notifications"
                name="notifications"
              >

              <Grid container direction="row" style={{marginTop: '25px'}, {marginBottom: '25px'}}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    classes={{h5: 'preview-title'}}
                    display='block'
                    align={'left'}
                  >{AppText.emails.infoTitle}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    classes={{body2: 'info-message'}}
                    align={'left'}
                  >{AppText.emails.infoMessage}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    classes={{body2: 'info-message'}}
                    align={'left'}
                  >{AppText.emails.infoMessageRight}</Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{marginTop: '25px'}, {marginBottom: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="template-label">Template: </InputLabel>
									<Select
										labelId="template-label"
										id="template-select"
										value={this.state.template === -1 ? '' : this.state.template}
										onChange={(e) =>
                    {
                      const inputs = [...this.state.createInputs];
                      for(let i = 0; i < inputs.length; i++)
                      {
                        inputs[i].value = '';
                        inputs[i].value = this.state.templates[e.target.value][inputs[i].id];
                      }

                      this.setState({ template: e.target.value, createInputs: inputs }, () =>
                      {
                        this._createForm.current.updateAllFormInputValues();
                      });
                    }}
									>
										{this.state.templates.map( (template, i) =>
										{
											return (<MenuItem value={i}>{template.templateName}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Grid>

              <div align="left" className="left-aligned create-form">
                {this.state.createInputs.length > 0 &&
                <Form
                  ref={this._createForm}
                  formInputs={this.state.createInputs}
                  formOnChange={(change, isFormValid) => this.setState({ [change.id.substr(change.id.lastIndexOf('.') + 1)]: change.value })}
                  showErrorList={false}
                  validateOnInit={false}
                  siteManager={this.props.siteManager}
                  columns={3}
                />}
              </div>

              <Grid container direction="row" className='bottom-section'>
                <Grid item xs={11}>
                  <Typography
                    variant="h5"
                    classes={{h5: 'preview-title'}}
                    display='block'
                    align={'left'}
                  >{AppText.emails.preview}</Typography>

                  <FormInput
                    data={{id: 'toEmail', type: 'text', name: 'toEmail', label: 'To email address: ', element: 'input'}}
                    rowClassName={''}
                    formInputOnChange={(change, notifyParent) => {
                      this.setState({ [change.id]: change.value });
                    }}
                    errorMessage={''}
                    value={this.state.toEmail}
                  />

                  <Button
  				          color="primary"
  				          startIcon={<VisibilityIcon />}
  									onClick={ () =>
                    {
                      this.renderTemplate();
                    }}
  				        >Send Test Email</Button>

                </Grid>

                <Grid item xs={1} className='main-btn-section'>
  				        <Button
  				          color="primary"
  				          startIcon={<SaveIcon />}
  									onClick={ () => this.state.template === -1 ? this.createTemplate() : this.updateTemplate() }
  				       	>Save</Button>
  				        <Button
  				          color="primary"
  				          startIcon={<ExitToAppIcon />}
  									onClick={ () =>
                    {
                      this._createForm.current.reset();
                    }}
  				        >Quit</Button>
                  {this.state.template !== -1 &&
                  <Button
  				          color="secondary"
  				          startIcon={<DeleteIcon />}
  									onClick={ () => this.deleteTemplate() }
  				       	>Delete</Button>}
  							</Grid>
              </Grid>
              </FormGroup>
            </FormControl>
          </DialogContent>
        </this._css>
      </Paper>
    );
  }

  styleComponent = (siteManager) =>
	{
    var bgColor = '#222';
    var textColor = '#FFF';
    if(siteManager !== null && siteManager !== undefined)
    {
      //console.log(props.siteManager);
      bgColor = siteManager.getColorFor('Header', 'Background');
      textColor = siteManager.getColorFor('Header', 'Text');
    }

		return StyledComponent.div`
      min-height: 80%;
      overflow-y: scroll;
      padding: 15px 15px;

      .friends-content
      {
        text-align: left;
      }
      .friends-title
      {
        color: ${bgColor};
      }
      .preview-title
      {
        color: ${bgColor};
      }
      .create-form
      {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .select-control
			{
				width: 100%

				@media(min-width: 600px)
				{
					width: 25%;
				}
			}
      .main-btn-section
      {
        align-self: flex-end;
      }
      .bottom-section
      {
        flex-direction: row;
      }
      .info-message
      {
       white-space:pre-wrap;
      }
		`;
	}
}
