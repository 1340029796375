import { ApiManager } from '../../managers';
import { Command } from '..';

/**
  Returns payment
*/
const DATA_SET_ID = 'authnet';
export async function ProcessPaymentCommand({
  setIsLoading,
  receiptId,
  paymentProfileId,
  externalId,
  opaqueData,
  billingInfo,
  cvv,
})
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tProcessPaymentCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'authnet',
        action: 'process-payment',
        params:
        {
          receiptId: receiptId,
          paymentProfileId: paymentProfileId,
          opaqueData: opaqueData ? JSON.stringify(opaqueData) : undefined,
          billingInfo: JSON.stringify(billingInfo),
          cvv: cvv,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      let dataSet = dataMgr.getData(DATA_SET_ID);
      dataSet[receiptId].receipt = response.results;
      dataMgr.setData(DATA_SET_ID, dataSet);

      setIsLoading(false);

      dataMgr.notifyObservers(DATA_SET_ID);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
