import React 		 from 'react';
import { Dashboard } from '../../../components/dashboard';

const Layout2 = ({
                    appLayout,
                    cookies,
                    selectedNavItem,
                    siteManager,
                    showAlert,
                    updateMasterState,
                    sideNavComponent
                }) =>
{
  return(
    <div>
      {(selectedNavItem !== -1 || appLayout === 2) &&
      <Dashboard
        showAlert={showAlert}
        cookies={cookies}
        siteManager={siteManager}
      />}
    </div>
  );
}
export default Layout2;
