import * as R from 'ramda';
import React, { forwardRef, useImperativeHandle } from 'react';
/*import {
  FaCreditCard,
  FaExclamationCircle,
  FaRegCalendarAlt
} from 'react-icons/fa';*/
import { Motion, spring } from 'react-motion';
import styled, { createGlobalStyle } from 'styled-components';
import {
  CCol,
} from '@coreui/react';
import Row from 'react-bootstrap/Row';
import { FormType, InjectedProps } from './acceptSelfHosted'
import { Input } from '../input'

const PayButton = styled.button(props => ({
  '&:hover': {
    cursor: !props.disabled ? 'pointer' : 'not-allowed'
  },
  backgroundColor: '#f6a4eb',
  border: '1px solid #2e6da4',
  borderRadius: '4px',
  boxShadow:
    '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6',
  color: 'white',
  fontSize: '1.1em',
  opacity: props.disabled ? 0.9 : 1,
  fontFamily: 'inherit',
  padding: '12px 48px',
  width: '100%'
}))

const CardNumber = ({
  type = 'text',
  name = 'cardNumber',
  placeholder = '1234 5678 9012 3456',
  label = 'Credit Card Number',
  ...props
}) =>
{
  return (
    <Input
      name="cardNumber"
      type={type}
      placeholder={placeholder}
      guide={false}
      label={label}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      {...props}
    />
  )
}

const CardCode = ({
  type = 'text',
  name = 'cardCode',
  placeholder = '',
  label = 'CVV',
  ...props
}) =>
{
  return (
    <Input
      name={name}
      type={type}
      placeholder={placeholder}
      guide={false}
      label={label}
      mask={[/\d/, /\d/, /\d/, /\d?/]}
      {...props}
    />
  )
}

const ExpDate = ({
  type = 'text',
  name = 'expDate',
  placeholder = 'MM/YY',
  label = 'Expiration Month/Year',
  ...props
}) =>
{
  return (
    <Input
      name={name}
      type={type}
      placeholder={placeholder}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      label={label}
      guide={false}
      {...props}
    />
  )
}

const ErrorComponent = (props) => {
  const slugs = {
    cardCode: 'Card code',
    cardNumber: 'Card number',
    expDate: 'Expiration date'
  }

  return (
    <div
      style={props.style}
      key={props.field}
      justifyContent="center"
      alignItems="center"
    >
      <p fontFamily="inherit" color="inherit" pl={2}>
        {slugs[props.field]} is not valid
      </p>
    </div>
  )
}


const FormComponent = forwardRef(({ style, ...props }, formComponentRef) =>
{
  const canSubmit = () => {
    console.log(props.validationErrors);
    const isValid = R.values(props.validationErrors).every((value) => {
      console.log(value);
      return value === true;
    });
    props.onValidChange(isValid);
  };

  const invalidFields = R.toPairs(props.validationErrors)
    .filter(([, status]) => !status)
    .map(([v]) => v)
    .slice(0, 1);

  useImperativeHandle(formComponentRef, () => ({
    canSubmit: () => canSubmit(),
  }));


  return (
    <div>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={6}>
          <CardNumber
            style={style && style.input}
            onFocus={R.curry(props.handleFocus)('cardNumber')}
            onBlur={props.handleBlur}
            onChange={R.curry(props.handleChange)('cardNumber')}
            focused={props.focused === 'cardNumber'}
            valid={props.validationErrors.cardNumber}
            value={props.values.cardNumber}
          />
        </CCol>
        <CCol md={6}>
          <ExpDate
            style={style && style.input}
            onFocus={R.curry(props.handleFocus)('expDate')}
            onBlur={props.handleBlur}
            onChange={R.curry(props.handleChange)('expDate')}
            valid={props.validationErrors.expDate}
            focused={props.focused === 'expDate'}
            value={props.values.expDate}
          />
        </CCol>
      </Row>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={6}>
          <CardCode
            style={style && style.input}
            onFocus={R.curry(props.handleFocus)('cardCode')}
            onBlur={props.handleBlur}
            onChange={R.curry(props.handleChange)('cardCode')}
            focused={props.focused === 'cardCode'}
            valid={props.validationErrors.cardCode}
            value={props.values.cardCode}
          />
        </CCol>
      </Row>

      <div>
        <Motion
          key={R.head(invalidFields)}
          style={{ opacity: spring(1) }}
          defaultStyle={{ opacity: 0 }}
        >
          {config => (
            <div style={config}>
              {R.head(invalidFields) ? (
                false && <ErrorComponent
                  key={R.head(invalidFields)}
                  field={R.head(invalidFields)}
                />
              ) : null}
            </div>
          )}
        </Motion>
      </div>
    </div>
  )
});

export default styled(FormComponent)({
  fontFamily: 'system-ui'
});
