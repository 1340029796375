import React from 'react';
import StyledComponent from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const Header = ({ title, description, siteManager }) =>
{
  console.log("\tPostHeader()");
  var bgColor = '#222';
  var textColor = '#FFF';
  if(siteManager !== null && siteManager !== undefined)
  {
    //console.log(props.siteManager);
    bgColor = siteManager.getColorFor('Header', 'Background');
    textColor = siteManager.getColorFor('Header', 'Text');
  }

  const _css = StyledComponent.div`
    .title
    {
      color: ${bgColor};
    }

    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
  `;

  return (
    <_css>
      <Typography
        variant="h3"
        classes={{h3: 'title'}}
        display='block'
        align={'center'}
      >{title}</Typography>
      <Typography
        variant="h6"
        display='block'
        align={'center'}
      >{description}</Typography>
  	</_css>);
}

function isEqual(prevProps, nextProps)
{
  return (
    prevProps.siteManager === nextProps.siteManager &&
    prevProps.description === nextProps.description
  );
}

export default React.memo(Header, isEqual);
