import React from 'react';
import TextField from '@material-ui/core/TextField';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

/**
  https://material-ui.com/components/text-fields/#textfield
    standard, filled, outlined
*/
const variant = "outlined";

const Layout2 = ({  filters,
                    filterValues,
                    onChange,
                    disabled,
                    selectEnabled }) =>
{
  console.log(filters);
  console.log(filterValues);
  return (
    <TableRow>
      {selectEnabled &&
      <TableCell
        align='left'
        padding={'none'}
      ></TableCell>}
      {filters.length > 0 &&
      filters.map((filter, index) => (
        <TableCell
          key={`table-filter-${index}`}
          align='left'
          padding={index === 0 ? 'none' : 'default'}
        >
          {filter.filter ?
          <TextField
            disabled={disabled}
            variant={variant}
            onChange={(evt) => onChange(filter.id, evt)}
            size="small"
            value={filterValues ? filterValues.get(filter.id) : ''}
          /> : <></>}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default Layout2;
