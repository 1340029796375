import { ApiManager } from '../../managers';
import { Command } from '..';

/**
  Returns receipt, formToken, and mode
*/
const DATA_SET_ID = 'authnet';
export async function FormTokenCommand({ setIsLoading, receiptId, origin, inHouseForm })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tFormTokenCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'authnet',
        action: 'form-token',
        params:
        {
          receiptId: receiptId,
          origin: origin,
          inHouseForm: inHouseForm,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      /*
        Holds map of dataSet.receiptIdMap[receiptId] =
        {
          receipt,
          formToken,
          isPaymentPage,
          mode,
          paymentProfiles
        }
      */
      let dataSet = dataMgr.getData(DATA_SET_ID);
      if(!dataSet)
      {
        dataSet = {}
      }
      dataSet[receiptId] =
      {
        receipt: response.results.receipt,
        formToken: response.results.formToken,
        isPaymentPage: response.results.isPaymentPage,
        mode: response.results.mode,
        paymentProfiles: response.results.paymentProfiles,
        apiLoginId: response.results.apiLoginId,
        publicKey: response.results.publicKey,
      };
      dataMgr.setData(DATA_SET_ID, dataSet);

      setIsLoading(false);

      dataMgr.notifyObservers(DATA_SET_ID);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
