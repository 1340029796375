import { ApiManager } from '../../managers';
import { Command } from '..';

export async function ViewRequestCommand({ setIsLoading, leadId })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tViewRequestCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'loan',
        action: 'view-request',
        params:
        {
          leadId: leadId,
        }
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }
      setIsLoading(false);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
