import React from 'react';
import StyledComponent from 'styled-components';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { AppText, Colors } from '../../constant';
import { ApiManager }  from '../../managers';
import { NotificationUtility } from '../../components/notifications';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default class Friends extends React.Component
{
  _isMounted = false;
	_css = null;

  // MARK: - Constructor
	constructor(props)
	{
		console.log('Friends()');
		super(props);
    this.state =
    {
      friends: []
    };

    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('Friends.componentDidMount()');
    this._isMounted = true;
    this.loadData();
  }


  // MARK: - API
	loadData = async () =>
	{
		try
		{
			console.log('Friends.loadData()');
			this.setState({ isLoading: true });
			const response = await ApiManager.SendRequest(
			{
				route: 'social',
				action: 'friends'
			});
			if(response.error !== null)
			{
				this.setState({ isLoading: false  });
				this.props.showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}

			this.setState({
				isLoading: false,
				friends: response.results,
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  render()
	{
    const userId = this.props.cookies.get('user')._id.toString();
    console.log(this.state.friends);
    console.log('Friends.render()');
    return (
      <Paper>
        <this._css>
          <DialogTitle disableTypography>
            <Typography
              variant="h3"
              classes={{h3: 'friends-title'}}
              display='block'
              align={'center'}
            >{AppText.friends.title}</Typography>
          </DialogTitle>
          <DialogContent /*dividers*/ className='friends-content'>
            <FormControl component="fieldset">
              <FormGroup
                aria-label="notifications"
                name="notifications"
              >
              {this.state.friends && this.state.friends.map( (relationship, i) =>
              {
                const friend = (relationship.createdBy.toString() === userId ? relationship.recipient : relationship.createdBy);

                console.log(friend);
                return (
                <Card className='friend-card' key={`friend-card-${i}`}>
                  <ButtonBase
                    className={'friend-card-btn'}
                    onClick={() =>
                    {
                      const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(friend._id.toString()) + '/';
                      this.props.updateMasterState({ redirect: redirect })
                    }}
                  >
                    <CardActionArea>
                      <CardContent className='friend-card-content'>
                        <Avatar
                          alt="Profile image"
                          src={friend.photo ? friend.photo : require('../../asset/no-photo.png')}
                          className={'friend-avatar'}
                          onClick={() =>
                          {
                            const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(friend._id.toString()) + '/';
                            this.props.updateMasterState({ redirect: redirect })
                          }}
                        />
                        <Typography gutterBottom variant="h5" component="h2" className={'friend-name'}>
                          {`${friend.firstName} ${friend.lastName}`}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </ButtonBase>
                  <CardActions className='friend-card-actions'>
                    <Button
                      size="small"
                      color="primary"
                      className='view-friend-btn'
                      onClick={() =>
                      {
                        const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(friend._id.toString()) + '/';
                         this.props.updateMasterState({ redirect: redirect })
                      }}
                    >View</Button>
                  </CardActions>
                </Card>);
              })}
              {this.state.friends.length === 0 &&
              <Typography
                variant="h6"
                className={'conversations-title'}
                display='block'
                align={'left'}
              >{AppText.friends.titleEmpty}</Typography>}
              </FormGroup>
            </FormControl>
          </DialogContent>
        </this._css>
      </Paper>
    );
  }

  styleComponent = (siteManager) =>
	{
    var bgColor = '#222';
    var textColor = '#FFF';
    if(siteManager !== null && siteManager !== undefined)
    {
      //console.log(props.siteManager);
      bgColor = siteManager.getColorFor('Header', 'Background');
      textColor = siteManager.getColorFor('Header', 'Text');
    }

		return StyledComponent.div`
      min-height: 80%;
      overflow-y: scroll;
      padding: 15px 15px;

      .friends-content
      {
        text-align: left;
      }
      .friends-title
      {
        color: ${bgColor};
      }
      .friend-card-btn
      {
        display: block;
        width: 100%;
      }
      .friend-avatar
      {
        width: 100%;
        height: 80px;
        display: flex;
        overflow: hidden;
        position: relative;
        font-size: 1.25rem;
        align-items: center;
        flex-shrink: 0;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        user-select: none;
        border-radius: 0px !important;
        justify-content: center;
        border: 3px solid #cecfd1;
        border-radius: 5px !important;
        margin: auto;
      }
      .view-friend-btn
      {
        width: 100%;
      }
      .friend-card
      {
        width: 160px;
        height: 180px;
        margin-right: 15px;
      }
      .friend-card-actions
      {
        padding: 0px !important;
        margin: 8px;
      }
      .friend-card-content
      {
        padding: 0px !important;
        margin: 12px;
      }
      .friend-name
      {
        color: #225373;
        font-weight: 600;
        line-height: 1.1;
        font-size: 1vw;

        @media(max-width: 1199px)
        {
          font-size: 15px;
        }
      }
		`;
	}
}
