import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import StyledComponent from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Colors from '../../constant/colors';
import { Container as Posts } from '../../components/post';
import FormLoader    from '../../components/Form/FormLoader';
import AppText from '../../constant/text';
import { Datetime } from '../../utils';
import { ApiManager } from '../../managers';
import { Request } from '../../api';
import { ContextMenu } from '.';
import defaultHeaderImg from '../../asset/profile-header-default.jpg';

/**
  @param  {int}   spacing     Spacing between cells
*/
class Profile extends React.Component
{
  _css = null;
  _profilePhotoUploader = null;
  _bannerPhotoUploader = null;


  constructor(props)
  {
    console.log("\tProfile()");
    super(props);

    this.state =
    {
      isLoading: false,
      selectedNavItem: -1,
      postsComponent: props.siteManager.getComponent('posts'),
      friendRequest: null,
      user: null,
      banner: null,
      editingQuote: false,
      contextMenuIsOpen: false,
      contextMenuAnchorElement: null,
    };

    // Style component
    this._css = this.styleComponent(props.siteManager);

    this._profilePhotoUploader = React.createRef();
    this._bannerPhotoUploader = React.createRef();
  }

  async componentDidMount()
	{
		console.log('\tProfile.componentDidMount()');
		this._isMounted = true;
		this.loadData();
	}

  // MARK: - API
	loadData = async () =>
	{
		console.log('View.loadData()');
    console.log('View: ' + JSON.stringify(this.state.entity));
		let user = this.props.cookies.get('user');
		if(user)
		{
			this.setState({ isLoading: true });

			try
			{
        var response = null;

        const params =
        {
          id: this.props.userId
        };
        response = await Request.sendRequest("post", params, "user/me", this.props.cookies.get('token'));

				if(response.data.error !== null)
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
					return;
				}

        console.log(response.data);


        const postsComponent = {...this.state.postsComponent};
        postsComponent.details = response.data.customDetails.posts;
				this.setState({
          banner: response.data.customDetails.banner,
					isLoading: false,
          postsComponent: postsComponent,
          friendRequest: response.data.customDetails.friendRequest,
          user: response.data.results
				});
			}
			catch(err)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
	}

  request = async() =>
  {
    // Fetch data
    this.setState({ isLoading: true });

    const response = await ApiManager.SendRequest(
    {
      route: 'social',
      action: 'request',
      id: this.props.userId
    });
    if(response.error !== null)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    this.setState({ friendRequest: response.results, isLoading: false });
  }

  /**
    userResponse is 1 or -1
  */
  respond = async(userResponse) =>
  {
    // Fetch data
    this.setState({ isLoading: true });

    const response = await ApiManager.SendRequest(
    {
      route: 'social',
      action: 'respond',
      id: this.state.friendRequest._id,
      response: userResponse
    });
    if(response.error !== null)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    this.setState({ friendRequest: response.results, isLoading: false });
  }

  /**
    Handle profile photo change
    @param  {Event} e   The event for the click that triggered this
  */
  profilePhotoOnChange = async(e) =>
  {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    console.log(file);

    // Fetch data
    this.setState({ isLoading: true });

    const updateParams =
    {
      photo: file
    };
    const response = await ApiManager.SendRequest(
    {
      route: 'data',
      action: 'update',
      model: 'user',
      id: this.props.cookies.get('user')._id,
      params: updateParams
    });
    if(response.error !== null)
    {
      this.setState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    this.props.cookies.set('user', response.results);
    this.setState({ user: response.results, isLoading: false });
  }


  uploadBannerPhoto = async(e) =>
  {
    try
		{
      e.stopPropagation();
      e.preventDefault();

      this.setState({ isLoading: true });
      const response = await ApiManager.SendRequest(
      {
        route: 'data',
        action: 'create',
        params:
        {
          purpose: 'banner',
          type: 'image',
          url: e.target.files[0]
        },
        model: 'file'
      });
      if(response.error !== null)
      {
        this.setState({ isLoading: false });
        this.props.showAlert(true, 'Un-oh', response.error, 'danger');
        return;
      }

      this.setState({ isLoading: false, banner: response.results.url });
      console.log(response.results);
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
  }

  render()
  {
    // TODO: Move selected nav IDX into nav list component and make it a reference we can read from
    const isSettingsOpen = (this.state.selectedNavItem === 'Settings');

    let friendRequestText = 'Add as a friend';
    let isRecipient = false;
    let responding = false;
    let friendBtnClassName = 'inactive';
    if(this.state.friendRequest)
    {
      isRecipient = this.state.friendRequest.recipient._id.toString() === this.props.cookies.get('user')._id.toString();
      switch(this.state.friendRequest.status)
      {
        case 'pending':
          friendRequestText = (isRecipient ? 'Respond to request' : 'Cancel pending request');
          responding = (isRecipient);
          break;
        case 'accepted':
          friendRequestText = 'Remove from friends';
          friendBtnClassName = 'active';
          break;
        default:
          break;
      }
    }

    console.log('\tProfile.render()');
    return (
    <this._css>
      <FormLoader isLoading={this.state.isLoading}/>

      {this.state.user &&
      this.state.user._id.toString() === this.props.cookies.get('user')._id.toString() &&
      <div className="edit-banner-photo">
        <input
          accept="image/*"
          type="file"
          id="banner-photo-uploader"
          ref={this._bannerPhotoUploader}
          style={{display: 'none'}}
          onChange={(e) => this.uploadBannerPhoto(e)}
        />
        <label
          className="edit-banner-photo"
          htmlFor="banner-photo-uploader">
          <Button
            className='icon'
            component="span"
            variant="contained"
          ><PhotoLibraryIcon /></Button>
        </label>
      </div>}

      {this.state.user &&
      <Paper
        elevation={3}
        className={'profile-header'}
        style={{'backgroundImage': `url(${this.state.banner ? this.state.banner : defaultHeaderImg})`}}
      >

        <Avatar
          alt="Profile image"
          src={this.state.user.photo ? this.state.user.photo : require('../../asset/no-photo.png')}
          className={'profile-avatar'}
        />
        <input
          accept="image/*"
          type="file"
          id="profile-photo-uploader"
          ref={this._profilePhotoUploader}
          style={{display: 'none'}}
          onChange={(e) => this.profilePhotoOnChange(e)}
        />
        {this.state.user._id.toString() === this.props.cookies.get('user')._id.toString() &&
        <label
          className="edit-profile-photo"
          htmlFor="profile-photo-uploader">
          <Button
            className='icon'
            component="span"
            variant="contained"
          ><PhotoLibraryIcon /></Button>
        </label>}

        <Grid className='profile-header-text'>
          <Typography
            variant="h6"
            display='block'
            align={'left'}
          >
            {this.state.user.firstName + ' ' + this.state.user.lastName}
          </Typography>


        </Grid>
      </Paper>}
      <Paper
        elevation={3}
        className={'profile-actions'}
      >
        {this.state.user &&
        <div className='btn-container message-btn-container'>
          <button
            className={'friend-btn friend-btn-inactive'}
            onClick={() => console.log('Send a message')}
          >
            <span className={`ti-comments action-icon action-icon-like-inactive`} />
            <span className="btn-text">{'Send a message'}</span>
          </button>
        </div>}

        {this.state.user &&
        <div className='btn-container friend-btn-container'>
          <button
            className={`friend-btn friend-btn-${friendBtnClassName}`}
            onClick={(e) =>
            {
              responding  ? this.setState({ contextMenuIsOpen: true, contextMenuAnchorElement: e.target })
                          : this.request();
            }}
          >
            <span className='ti-face-smile action-icon'/>
            <span className="btn-text">{friendRequestText}</span>
          </button>
          <ContextMenu
            closeMenu={() => this.setState({ contextMenuIsOpen: false, contextMenuAnchorElement: null }) }
            acceptOnClick={() =>
            {
              this.setState({ contextMenuIsOpen: false, contextMenuAnchorElement: null });
              this.respond(1);
            }}
            declineOnClick={() =>
            {
              this.setState({ contextMenuIsOpen: false, contextMenuAnchorElement: null });
              this.respond(-1);
            }}
            anchorElement={this.state.contextMenuAnchorElement}
            isOpen={this.state.contextMenuIsOpen}
          />
        </div>}
      </Paper>
      <Posts
        dynamicData={false}
        user={this.props.cookies.get('user')}
        component={this.state.postsComponent}
        siteManager={this.props.siteManager}
        updateMasterState={(state) => this.setState(state)}
        showAlert={this.props.showAlert}
      />
    </this._css>);
  }

  // Style component
  styleComponent = (siteManager) =>
  {
    //  let btnColor = siteManager.getColorFor('Form', 'Submit Button');
    return StyledComponent.div`
      .profile-header
      {
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 76px;
        display: flex;
        background-size: cover;
      }
      .profile-actions
      {
        background-color: #235373;
        padding: 20px;
        display: flex;

        @media only screen and (max-width: 768px)
        {
          flex-direction: column;
          margin-top: 5px;
        }
      }
      .profile-header-text
      {
        padding-left: 10px;
        color: #FFF !important;
        flex-direction: column;
        display: flex;
      }
      .profile-avatar
      {
        width: 160px;
        height: 160px;
        display: flex;
        overflow: hidden;
        position: relative;
        font-size: 1.25rem;
        align-items: center;
        flex-shrink: 0;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        user-select: none;
        border-radius: 0px !important;
        justify-content: center;
        border: 3px solid #cecfd1;
        border-radius: 5px !important;
      }

      .btn-container
      {
        width: 25%;
        text-align: center;

        @media only screen and (max-width: 992px) and (min-width: 769px)
        {
          width: 50%;
        }
        @media only screen and (max-width: 768px)
        {
          width: 100%;
          margin-top: 10px;
        }
        @media only screen and (max-width: 1409px)
        {
          margin-right: 10px;
        }

        button
        {
          border-radius: 40px;
          height: 55px;
          line-height: 12px;
          text-align: center;
          position: relative;
          width: 100%;

          .action-icon
          {
            border-radius: 50%;
            padding: 6px;
            font-size: 28px;
            position: absolute;
            top: 5px;
            left: 5px;
            line-height: 28px;
            margin-right: 30px;
          }
          .action-icon-like-inactive
          {
            background-color: ${Colors.posts.post.actions.like.inactive.secondary.color};
            color: ${Colors.posts.post.actions.like.inactive.primary.color};
          }
          .action-icon-like-active
          {
            background-color: ${Colors.posts.post.actions.like.active.secondary.color};
            color: ${Colors.posts.post.actions.like.active.primary.color};
          }
          .btn-text
          {
            padding-left: 10px;
            font-weight: 400;
            font-size: 1.1vw;

            @media only screen and (max-width: 992px)
            {
              font-size: 14px;
            }
          }
        }
      }
      .message-btn-container
      {
        @media only screen and (min-width: 1410px)
        {
          float: left;
        }

        .like-thumb
        {
          color: ${Colors.posts.post.actions.like.active.primary.color};
        }
      }

      .friend-btn-container
      {
        @media only screen and (min-width: 1410px)
        {
          float: right;
        }
      }
      .friend-btn-inactive
      {
        color: ${Colors.posts.post.actions.like.inactive.secondary.color};
        background: ${Colors.posts.post.actions.like.inactive.primary.color};
      }
      .friend-btn-active
      {
        color: ${Colors.posts.post.actions.like.active.secondary.color};
        background: ${Colors.posts.post.actions.like.active.primary.color};
      }
      .edit-profile-photo
      {
        position: absolute;
        margin-left: 2px;
        margin-top: 2px;
      }
      .edit-banner-photo
      {
        text-align: left;
        margin-left: 2px;
        margin-top: 2px;
        position: absolute;
      }
      .edit-quote
      {
        .edit-icon
        {
          max-width: 20px;
          max-height: 20px;
          min-height: 20px;
          min-width: 20px;
          margin-right: 8px;
        }

        margin-top: auto;
        display: flex;
        flex-direction: row;
      }
      .edit-quote-text-field
      {
        .MuiInputLabel-filled
        {
          color: #FFF;
        }
        .Mui-focused:
        {
          color: #FFF;
        }
        .MuiInputBase-input
        {
          color: #FFF;
        }
        &.MuiFilledInput-underline:after:
        {
          borderBottomColor: #FFF;
        }
        .MuiFilledInput-inputMultiline:
        {
          color: #FFF;
        }
      }
      .icon
      {
        background-color: unset;
        color: #FFFFFF;
        box-shadow: unset;
      }
    `;
  }
}

export default Profile;
