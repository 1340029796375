import React from 'react';

import { Table } from '../../Table';

const Tables = ({ tables }) =>
{
  return (
    tables.map( (table, i) =>
    {
      return (
        <div className={'data-manager-large-table'} style={{ marginBottom: '25px' }}>
          <Table
            data={table.data}
            headers={table.modelDoc.tableProperties.headers}
            model={table.model}
            schemaFields={table.modelDoc.schemaFields}

            selectEnabled={false}
            selectAllEnabled={false}
            multiSelectEnabled={false}

            defaultSort={table.defaultSort}
            sortEnabled={true}

            tableDidStartLoading={(action) => console.log(action)}
            tableDidFinishLoading={(action, message, error) => console.log(action)}

            title={table.title}
            titleIcon={'cil-user'}

            isDeleteAvailable={false}

            updateForm={{
              isEnabled: false
            }}
            updateFormInputs={[]}

            isCreateAvailable={false}
            createFormInputs={[]}
            createForm={{}}

            viewForm={{}}

            isCsvAvailable={true}

            paginationEnabled={false}

            cookies={null}
            siteManager={null}

            layout={2}
          />
        </div>
      )
    })
  );
}

export default Tables;
