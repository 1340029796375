import React from 'react';
import StyledComponent from 'styled-components';

import { DataManager } from '../../managers';
import { LoadDashboardCommand } from '../../command/dashboard';
import Layout1 from './layout-1';
const COMPONENT_NAME = 'dashboard';

class Dashboard extends React.Component
{
  _layout = 2;
  _dataMgr = null;
  _isMounted = false;

  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tDashboard()");
    super(props);
    this.state =
    {
      isLoading: false,
      dataVersion: 0, // Allow data manager to update us using this
    };

    this._dataMgr = DataManager.GetInstance();
  }

  async componentDidMount()
  {
    console.log('\tNavigationBar.componentDidMount()');
    this._isMounted = true;

    // Setup data manager listener to detect when app changes
    this._dataMgr.addObserver(COMPONENT_NAME,
    () =>
    {
      this.setState({ dataVersion: this.state.dataVersion + 1 });
    },
    'dashboard');

    // Load data
    if(this.props.cookies.get('token'))
    {
      await this.loadData();
    }
  }

  componentWillMount()
  {
    this._dataMgr.removeObserver(COMPONENT_NAME);
  }

  loadData = async() =>
  {
    // Fetch data
    await this._dataMgr.execute(await new LoadDashboardCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading })
    }));
  }

  render()
  {
    const data = this._dataMgr.getData('dashboard');
    console.log(data);
    return (
      <Layout1
        isLoading={this.state.isLoading}
        widgets={data.widgets}
      />
    );
  }
}

export default Dashboard;
