import React from 'react';
import StyledComponent from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Datetime } from '../../utils';
import { AppText, Colors, Images } from '../../constant';
import { ApiManager, DataManager } from '../../managers';


export default class Search extends React.Component
{
  _css = null;
  _isMounted = false;
  _dataMgr = null;

  constructor(props)
	{
		console.log('\tSearch()');
		super(props);
    this.state =
    {
      data: [],
      searchText: ''
    };

    this._css = this.styleComponent(props.siteManager);

    this._popupRef = React.createRef();
    this._dataMgr = DataManager.GetInstance();
  }

  async componentDidMount()
  {
    console.log('\tSearch.componentDidMount()');
    this._isMounted = true;
  }

  render()
  {
    console.log('\tSearch.render()');
    return (
      <this._css>
        <div className={'container'}>
          <div className={'search-icon'}>
            <SearchIcon />
          </div>
          <Autocomplete
            className={'text-input'}
            classes={{
              inputRoot: 'input-root',
              inputFocused: 'input-focused'
            }}
            noOptionsText={AppText.search.noResults}
            options={this.state.data}
            groupBy={(option) => option.type}
            getOptionLabel={(option) => option.displayText}
            style={{ width: 300 }}
            onChange={(evt, option, reason) =>
            {
              if(option)
              {
                //this.props.forward(option.redirect);
                this.props.history.push(option.redirect);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                variant="outlined"
                onChange={async(evt) =>
                {
                  const results = await this._dataMgr.searchAllDataSets(evt.target.value);
                  this.setState({ data: results });
                }}
                inputProps={{
                  // To disable browser auto complete on this field
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </div>
      </this._css>
    );
  }

  styleComponent = (siteManager) =>
	{
		return StyledComponent.div`
      .container
      {
        position: relative;
        border-radius: 25px;
        background-color: ${Colors.search.background};
        margin-left: 0px;
        width: 70%;
        &:focus-within
        {
          width: 100%;
        }
        transition: width 300ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      .search-icon
      {
        padding-top: 4px;
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-ttems: center;
        justify-content: center;
        color:#FFF;
      }
      .text-input
      {
        /*padding: 4px;*/
        padding-left: 30px;
        width: 100%;
        background-color: transparent;
        border: 0px;
        color: #FFF;
      },
      .MuiFormControl-root
      {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
      .MuiOutlinedInput-notchedOutline
      {
        border-width: 0px !important;
      }
      .MuiAutocomplete-endAdornment
      {
        &:not(:focus)
        {
          display: none;
        }
        &:focus-within
        {
          display: block;
        }
      }
      .input-root
      {
        padding: 0px !important;
        border-color: transparent !important;
        border-width: 0px;
        color: #FFF;
      }
      .input-focused
      {
        border-color: transparent !important;
        outline-color: transparent !important;
        color: #FFF !important;
      }

		`;
	}
}
