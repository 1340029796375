import React from 'react';
import StyledComponent from 'styled-components';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { AppText, Colors } from '../../constant';
import ApiRequest  from '../../api/request.js';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default class Settings extends React.Component
{
  _isMounted = false;
	_css = null;

  // MARK: - Constructor
	constructor(props)
	{
		console.log('Settings()');
		super(props);
    this.state =
    {
      canPush: this.props.pushManager !== null ? this.props.pushManager.hasPushToken() : false,
      subscribableEvents: this.props.pushManager ? this.props.pushManager.subscribableEvents() : [],
      subscribableEventsNeedToSave: false, // Flag so we don't need to save if no changes occur
    };

    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('Settings.componentDidMount()');
    this._isMounted = true;
  }

  /**
		Save subscription preferences
	*/
	pushSubscribe = async () =>
	{
		this.props.updateMasterState({ isLoading: true });
		try
		{
			let params = { subscribableEvents: this.state.subscribableEvents };
      console.log(params);
			let response = await ApiRequest.sendRequest("post", params, "push/subscribe", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
        this.props.updateMasterState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}
			console.log(response.data.results);
			this.props.updateMasterState({ isLoading: false });
      this.setState({ subscribableEventsNeedToSave: false });
		}
		catch(err)
		{
			this.props.updateMasterState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  render()
	{
    console.log('Settings.render()');
    return (
      <Paper>
        <this._css>
          <DialogTitle disableTypography>
          <Typography
            variant="h3"
            classes={{h3: 'settings-title'}}
            display='block'
            align={'center'}
          >{AppText.settings.title}</Typography>
          </DialogTitle>
          <DialogContent /*dividers*/ className='settings-content'>
            {!this.state.canPush &&
            <div>
              <Typography
                variant="h6"
                classes={{h6: 'settings-header-item'}}
              >{AppText.settings.notifications.title.disabled}</Typography>
              <Typography
                variant="subtitle1"
                classes={{subtitle1: 'settings-header-item'}}
              >{AppText.settings.notifications.subtitle}</Typography>
              <Button
                onClick={async() =>
                {
                  const result = await this.props.pushManager.subscribeToPush();
                  this.setState({ canPush: result });
                }}
                classes={{root: 'settings-toggle-button'}}
              >{AppText.settings.notifications.submit.disabled}</Button>
            </div>}

            {this.state.canPush &&
            <FormControl component="fieldset">
              <Typography
                variant="h5"
                classes={{h5: 'settings-header-item'}}
              >{AppText.settings.notifications.title.enabled}</Typography>
              <FormGroup
                aria-label="notifications"
                name="notifications"
              >
                {this.state.subscribableEvents.map( (event, i) =>
                {
                  return (
                  <div key={'settings-' + event.modelType + '-' + i + '-row'}>
                    <Typography
                      key={'settings-' + event.modelType + '-' + i + '-text'}
                      variant="h6"
                      classes={{h6: 'settings-header-item'}}
                    >{event.modelType.charAt(0).toUpperCase() + event.modelType.slice(1) + 's'}</Typography>
                    {event.actions.map( (action, j) =>
                    {
                      return (
                        <FormControlLabel
                          key={'settings-' + event.modelType + '-' + i + '-' + action.action + '-' + j + '-checkbox'}
                          value={event.modelType + '-' + action.action}
                          control={<Checkbox classes={{checked: 'settings-item-checked'}} />}
                          checked={action.checked}
                          onChange={(e) =>
                          {
                            let subscribableEvents = [...this.state.subscribableEvents];
                            subscribableEvents[i].actions[j].checked = e.target.checked;
                            this.setState({ subscribableEvents: subscribableEvents, subscribableEventsNeedToSave: true }, () =>
                            {
                              this.pushSubscribe();
                            });
                          }}
                          label={action.label}
                        />
                      );
                    })}
                  </div>
                  );
                })}
              </FormGroup>
            </FormControl>}
          </DialogContent>
        </this._css>
      </Paper>
    );
  }

  styleComponent = (siteManager) =>
	{
    var bgColor = '#222';
    var textColor = '#FFF';
    if(siteManager !== null && siteManager !== undefined)
    {
      //console.log(props.siteManager);
      bgColor = siteManager.getColorFor('Header', 'Background');
      textColor = siteManager.getColorFor('Header', 'Text');
    }

		return StyledComponent.div`
      .settings-title
      {
        color: ${bgColor};
      }
      .settings-content
      {
        text-align: left;
      }
			.settings-close-btn
			{
				position: absolute;
		    right: 8px;
		    top: 8px;
		    color: ${Colors.settings.closeButton.color};
			}
      .settings-toggle-button
      {
        transition: all 80ms linear;
        background: ${Colors.settings.toggleButton.color};
        color: #fff;
        font-weight: 900;
        box-shadow: none;
        margin-top: 8px;
      }
      .settings-header-item
      {
        margin-top: 8px;
      }
      .settings-item-checked
      {
        color: ${Colors.settings.checkbox.color} !important;
      }
		`;
	}
}
