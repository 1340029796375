import ApiRequest  from '../api/request.js';


export default class Shop
{
  /**
    Interact with data routes
    @note Parameters come in an object named params and may contain more than what is just labeled here
    look at actual method called for action to determine actual parameters
    @param {String}  params.model is the model type being interacted
    @param  {String}  params.action   Is the action to take (create|delete|update)
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    console.log('\t\tData.Interact()');
    try
    {
      if(params.action === 'add-product')
      {
        return Shop.Create(params, apiToken);
      }
      else if(params.action === 'update-product')
      {
        return Shop.Update(params, apiToken);
      }
      else
      {
        throw Error('Invalid action sent to Social.interact');
      }
    }
    catch(err)
    {
      return {error: err.message};
    }
  }



  /**
    Create new model document
    @note Parameters come in an object named params
    @param {JSON}  params.params  is the parameters to create with
    @param {String}  params.model is the model type being created
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Create(params, apiToken)
  {
    console.log('\t\tShop.Create()');
    try
    {
      // Convert to form data
      const formData = new FormData();
      const paramKeys = Object.keys(params.params);
      for(var i = 0; i < paramKeys.length; i++)
      {
        // TODO: Make this somehow check the schema fields and make sure it knows what types are files
        if(paramKeys[i] !== 'contract')
        {
          formData.set(paramKeys[i], params.params[paramKeys[i]]);
        }
        else
        {
          formData.append(paramKeys[i], params.params[paramKeys[i]]);
        }
      }

      let response = await ApiRequest.sendRequest("post", formData, 'shop/' + params.action, apiToken, 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }


  /**
    Update model document
    @note Parameters come in an object named params
    @param {JSON}  params.params  is the parameters to create with
    @param {String}  params.model is the model type being created
    @param  {String}  params.id   Is the id of the document being updated
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Update(params, apiToken)
  {
    console.log('\t\tShop.Update()');
    console.log(params);
    try
    {
      // Convert to form data
      const formData = new FormData();
      const paramKeys = Object.keys(params.params);
      for(var i = 0; i < paramKeys.length; i++)
      {
        if(paramKeys[i] !== 'contract')
        {
          formData.set(paramKeys[i], params.params[paramKeys[i]]);
        }
        else
        {
          formData.append(paramKeys[i], params.params[paramKeys[i]]);
        }
      }
      formData.set('id', params.id);

      let response = await ApiRequest.sendRequest("post", formData, 'shop/' + params.action, apiToken, 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}
