import React, { Suspense } from 'react';
import StyledComponent from 'styled-components';

import {
  CCol,
  CRow,
  CWidgetProgress,
  CProgress,

  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
  CSwitch,
  CInputRadio,

  CForm,
  CLabel,
  CInputGroup,
  CInputGroupAppend,
  CInput,

  CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';

import { AppText, Colors } from '../../constant';
import FormLoader  from '../../components/Form/FormLoader';

const Layout1 = ({  isLoading,
                    thirdPartySharing,
                    updateData,
                    setProperty,
                }) =>
{
  return (
    <_css>

      <FormLoader
        isLoading={isLoading}
      />

      {thirdPartySharing === null &&
      !isLoading &&
      <p className={'message'}>Invalid URL</p>}

      {isLoading &&
      <p className={'message'}>Loading</p>}

      {/* Editing product */}
      {thirdPartySharing !== null &&
      <CCol
        sm="8"
        md="8"
        lg="8"
        xl="8"
      >
        <CCard>
          <CCardHeader>
            <div className={'edit-top-actions'}>
              Update Third Party Sharing Preferences
            </div>
          </CCardHeader>
          <CCardBody>
            <CForm className="form-horizontal">
              <div>
                <div className="controls">
                  <CInputGroup>
                    <input
                      type="radio"
                      id={`third-party-enabled`}
                      name={''}
                      value={true}
                      checked={thirdPartySharing === true}
                      onChange={(e) =>
                      {
                        setProperty(true);
                      }}
                    />
                    <label
                      htmlFor={`third-party-enabled`}
                      className={'radio-btn-label'}
                    >{'Enabled'}</label>
                  </CInputGroup>
                </div>
              </div>
              <div>
                <div className="controls">
                  <CInputGroup>
                    <input
                      type="radio"
                      id={`third-party-disabled`}
                      name={''}
                      value={false}
                      checked={thirdPartySharing === false}
                      onChange={(e) =>
                      {
                        setProperty(false);
                      }}
                    />
                    <label
                      htmlFor={`third-party-disabled`}
                      className={'radio-btn-label'}
                    >{'Disabled'}</label>
                  </CInputGroup>
                </div>
              </div>
            </CForm>
          </CCardBody>
          <CCardFooter className={'permission-actions'}>
            <div>
              <CButton
                  type="submit"
                  size="md"
                  color="success"
                  onClick={updateData}
                  className={'success-btn'}
                ><CIcon name="cil-scrubber" /> Save</CButton>
            </div>
          </CCardFooter>
        </CCard>
      </CCol>}

    </_css>
  );
}


const _css = StyledComponent.div`
  .authorization-container
  {
    cursor: pointer;

    .card
    {
      background-color: inherit;
    }
  }
  .permission-container
  {
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
  }
  .permission-card
  {
    display: flex;
    justify-content: space-around;
  }
  .success-btn
  {
    margin-right: 25px;
  }
  .permission-actions
  {
    display: flex;
    justify-content: space-between;
  }
  .edit-top-actions
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .input-group
  {
    align-items: baseline;
  }
  .radio-btn-label
  {
    margin-left: 5px !important;
  }
  .contract-container
  {
    flex-direction: column;
    display: flex;
  }
  .message
  {
    text-align: center;
    color: black;
  }
`;

export default Layout1;
