import React from 'react';
import StyledComponent from 'styled-components';
import {
  CCol,
  CRow,

  CCard,
  CCardBody,
  CCardHeader,

  CForm,
  CFormGroup,
  CSelect,
  CLabel,
  CInputGroup,
  CInputGroupAppend,
  CInput,
  CTextarea,

  CButton,
} from '@coreui/react';
import Qs from 'query-string';

import { FormLoader } from '../../components/Form';
import { DataManager } from '../../managers';
import { ViewRequestCommand, VendorResponseCommand } from '../../command/loan';
import {
  ViewReferralCommand,
  VendorReferralCommand,
  ReferralResponseCommand,
} from '../../command/referral';
import { AppText } from '../../constant';

const COMPONENT_NAME = 'approve';

/**
  Allow vendor to approve loan request
*/
export default class Approve extends React.Component
{
	_isMounted = false;
  _dataMgr = null;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);
		console.log('Approve()');

    this.state =
    {
      isLoading: false,
      // Loan approval related
      loan:
      {
        amount: 0,
        status: '',
        lead: null,
        declineReason: '',
      },
      // Vendor referral related
      vendorReferral: null,
      medicalVendors: [],
      medicalVendor: null,

      // loan,referral
      mode: '',

      dataVersion: 0, // Allow data manager to update us using this
    };

    this._dataMgr = DataManager.GetInstance();
	}

	componentDidMount()
	{
    console.log('Approve.componentDidMount()');
    this._isMounted = true;

    this.loadData();

    // Setup data manager listener to detect when app changes
    this._dataMgr.addObserver(COMPONENT_NAME,
    () =>
    {
      this.setState({ dataVersion: this.state.dataVersion + 1 });
    },
    COMPONENT_NAME);
	}
	componentWillUnmount()
	{
		this._isMounted = false;
    this._dataMgr.removeObserver(COMPONENT_NAME);
	}


  // MARK: - API
  loadData = async() =>
  {
    try
    {
      const splitUrl = this.props.location.search.split('=');
      if(splitUrl.length === 2)
      {
        let querystring = Qs.parse(this.props.location.search);
        if(querystring.opportunityId)
        {
          this.setState({ mode: 'loan' });
          this.loadLoan(querystring.opportunityId);
        }
        else if(querystring.referralId)
        {
          this.setState({ mode: 'referral' });
          this.loadReferral(querystring.referralId);
        }
      }
    }
    catch(err)
    {
      console.log(err);
    }
  }

  loadLoan = async(opportunityId) =>
  {
    const response = await this._dataMgr.execute(await new ViewRequestCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
      leadId: opportunityId
    }));

    let loan = null;
    if(response.loan)
    {
      loan = response.loan;
    }
    else
    {
      loan = {...this.state.loan};
    }

    loan.lead = response.lead;
    loan.amount = response.lead.requestedLoan ? response.lead.requestedLoan : 0;

    this.setState({
      loan: loan,
      vendorReferral: response.vendorReferral,
      medicalVendors: response.medicalVendors,
    });
  }

  loadReferral = async(referralId) =>
  {
    const response = await this._dataMgr.execute(await new ViewReferralCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
      referralId: referralId
    }));

    this.setState({
      vendorReferral: response,
    });
  }

  updateLoan = async(approve) =>
  {
    const result = await this._dataMgr.execute(await new VendorResponseCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
      leadId: this.state.loan.lead._id,
      amount: this.state.loan.amount,
      approve: approve,
      declineReason: this.state.loan.declineReason,
    }));

    if(result.status === 'pending')
    {
      let message = `Loan submitted for approval.`;
      if(result.lead &&
      result.lead.vendor &&
      result.lead.vendor.type &&
      result.lead.vendor.type.type === 'legal') {
         message += ` Please refer a physical therapist / chiropractor to ${this.state.loan.lead.createdBy.firstName} ${this.state.loan.lead.createdBy.lastName}`;
      }
      this.props.showAlert(true,
        'Success',
        message,
        'success'
      );
    }

    if(result)
    {
      this.setState({ loan: result });
    }
  }

  referVendor = async() =>
  {
    const result = await this._dataMgr.execute(await new VendorReferralCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
      leadId: this.state.loan.lead._id,
      vendorId: this.state.medicalVendor,
    }));

    if(result.status === 'pending')
    {
      this.props.showAlert(true,
        'Success',
        `Referral submitted for approval. We'll email you when ${result.vendor.name} responds`,
        'success'
      );
    }

    if(result)
    {
      this.setState({ vendorReferral: result });
    }
  }

  updateReferral = async(approve) =>
  {
    const result = await this._dataMgr.execute(await new ReferralResponseCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
      vendorReferralId: this.state.vendorReferral._id,
      approve: approve,
      declineReason: this.state.vendorReferral.declineReason,
    }));

    if(result)
    {
      this.props.showAlert(true,
        'Success',
        `Referral ${result.status}`,
        'success'
      );
      this.setState({ vendorReferral: result });
    }
  }


	// MARK: - Render
  renderLoanApproval = () => {
    return (
      <CRow>
        <CCol
          sm="7"
          md="5"
          lg="6"
          xl="5"
        >
          <CCard>
            <CCardHeader>{`${this.state.loan.lead.createdBy.firstName} ${this.state.loan.lead.createdBy.lastName} Loan Approval - The requested loan amount can be increased or decreased as you see fit.`}</CCardHeader>
            <CCardBody>
              <CForm className="form-horizontal">
                {/* Amount */}
                <div>
                  <CLabel htmlFor="appendedInputButton">Loan Amount</CLabel>
                  <div className="controls">
                    <CInputGroup>
                      <CInput
                        id="product-price"
                        onChange={(e) => {
                          const loan = {...this.state.loan};
                          loan.amount = e.target.value;
                          this.setState({ loan: loan });
                        }}
                        size="32"
                        type="number"
                        value={this.state.loan.amount}
                      />
                    </CInputGroup>
                  </div>
                </div>
                {/* Decline Reason */}
                <div style={{ marginTop: 20 }}>
                  <CLabel htmlFor="appendedInputButton">If the loan is declined, please provide an explanation below</CLabel>
                  <div className="controls">
                    <CInputGroup>
                      <CTextarea
                        id="product-decline-reason"
                        onChange={(e) => {
                          const loan = {...this.state.loan};
                          loan.declineReason = e.target.value;
                          this.setState({ loan: loan });
                        }}
                        size="1024"
                        type="text"
                        value={this.state.loan.declineReason}
                      />
                    </CInputGroup>
                  </div>
                </div>
                <div className={'vendor-actions'}>
                  <CButton
                    style={{marginTop: '5px'}}
                    color="success"
                    onClick={() => this.updateLoan(true)}
                  >Request Approval</CButton>
                  <CButton
                    style={{marginTop: '5px'}}
                    color="danger"
                    onClick={() => this.updateLoan(false)}
                  >Reject Request</CButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }

  renderVendorReferral = () => {
    return (
      <CRow>
        <CCol
          sm="9"
          md="9"
          lg="9"
          xl="9"
        >
          <CCard>
            <CCardHeader>{`Choose a physical therapist / chiropractor to refer to ${this.state.loan.lead.createdBy.firstName} ${this.state.loan.lead.createdBy.lastName}`}</CCardHeader>
            <CCardBody>
              <CForm className="form-horizontal">
                <CFormGroup>
                  <CLabel htmlFor="appendedInputButton">{'Vendor'}</CLabel>
                  <div className="controls">
                    <CInputGroup>
                      <CSelect
                         custom
                         name="medicalVendors"
                         id="medicalVendors"
                         onChange={(e) => this.setState({ medicalVendor: e.target.value })}
                       >
                         <option>Select a vendor</option>
                         {this.state.medicalVendors &&
                         this.state.medicalVendors.map( (vendor, i) =>
                         {
                           return (
                             <option value={vendor._id.toString()}>{`${vendor.name} - ${vendor.location.city}, ${vendor.location.state}`}</option>
                           );
                         })}
                     </CSelect>
                      <CInputGroupAppend>
                        <CButton
                          color="success"
                          disabled={!this.state.medicalVendor}
                          onClick={() => this.referVendor()}
                        >Create!</CButton>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </div>
                </CFormGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }

  renderReferralResponse = () => {
    return (
      <CRow>
        <CCol
          sm="9"
          md="9"
          lg="9"
          xl="9"
        >
          <CCard>
            <CCardHeader>{`${this.state.vendorReferral.lead.createdBy.firstName} ${this.state.vendorReferral.lead.createdBy.lastName} Referral Approval`}</CCardHeader>
            <CCardBody>
              <CForm className="form-horizontal">
                <div>{`Full name: ${this.state.vendorReferral.lead.createdBy.firstName} ${this.state.vendorReferral.lead.createdBy.lastName}`}</div>
                <div>{`Phone: ${this.state.vendorReferral.lead.createdBy.phone}`}</div>
                <div>{`Email: ${this.state.vendorReferral.lead.createdBy.email}`}</div>
                <div>{`Location: ${this.state.vendorReferral.lead.location.city}, ${this.state.vendorReferral.lead.location.state} ${this.state.vendorReferral.lead.location.zip}`}</div>
                <div>{`Referred by: ${this.state.vendorReferral.vendor.name}`}</div>
                {/* Decline Reason */}
                <div style={{ marginTop: 20 }}>
                  <CLabel htmlFor="appendedInputButton">If the referral is declined, please provide an explanation below</CLabel>
                  <div className="controls">
                    <CInputGroup>
                      <CTextarea
                        id="product-decline-reason"
                        onChange={(e) => {
                          const vendorReferral = {...this.state.vendorReferral};
                          vendorReferral.declineReason = e.target.value;
                          this.setState({ vendorReferral });
                        }}
                        size="1024"
                        type="text"
                        value={this.state.vendorReferral.declineReason}
                      />
                    </CInputGroup>
                  </div>
                </div>
                <div className={'vendor-actions'}>
                  <CButton
                    style={{marginTop: '5px'}}
                    color="success"
                    onClick={() => this.updateReferral(true)}
                  >Approve</CButton>
                  <CButton
                    style={{marginTop: '5px'}}
                    color="danger"
                    onClick={() => this.updateReferral(false)}
                  >Reject</CButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }

  render()
	{
		console.log('Approve.render()');
    console.log(this.state);

		return (
      <_css>
        <FormLoader
          isLoading={this.state.isLoading}
          key={`payments-loader`}
        />

        {/* Finished */}
        {this.state.loan.lead &&
        this.state.loan.status &&
        this.state.vendorReferral &&
        <p className={'message'}>Thank you! No further action required</p>}

        {/* Invalid */}
        {!this.state.loan.lead &&
        !this.state.vendorReferral &&
        !this.state.isLoading &&
        <p className={'message'}>Invalid approval page. Please contact support</p>}

        {/* Referral was rejected/approved */}
        {((this.state.vendorReferral &&
        (this.state.vendorReferral.status === 'declined' || this.state.vendorReferral.status === 'approved')) ||
        ((this.state.loan.status === 'pending' ||
          this.state.loan.status === 'approved') &&
        this.state.vendorReferral === null &&
        this.state.loan.lead &&
        this.state.loan.lead.vendor &&
        this.state.loan.lead.vendor.type &&
        this.state.loan.lead.vendor.type.type !== 'legal')) &&
        <p className={'message'}>Thank you! No further action required</p>}

        {/* Loan not acted on yet, let attorney do it */}
        {this.state.loan.status === '' &&
        this.state.loan.lead &&
        this.renderLoanApproval()}

        {/* Loan is pending/approved and no referral given yet (legal vendor only) */}
        {(this.state.loan.status === 'pending' ||
          this.state.loan.status === 'approved') &&
        this.state.vendorReferral === null &&
        this.state.loan.lead &&
        this.state.loan.lead.vendor &&
        this.state.loan.lead.vendor.type &&
        this.state.loan.lead.vendor.type.type === 'legal' &&
        this.renderVendorReferral()}

        {/* Referral is pending and requires response */}
        {this.state.vendorReferral &&
        this.state.vendorReferral.status === 'pending' &&
        this.state.mode === 'referral' &&
        this.renderReferralResponse()}
      </_css>
		);
	}
}

const _css = StyledComponent.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .edit-top-actions
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .authnet-form
  {
    height: 600px;
  }

  #iframeAuthorizeNet
  {
    height: 600px;
  }

  .message
  {
    text-align: center;
    color: black;
  }
  .vendor-actions
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  .
`;
