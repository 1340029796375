import React from 'react';
import { Datetime } from '../../utils';

// The message component takes the message text and the username of the message
// sender. It also takes `self` - a boolean value depicting whether the message
// is sent by the current logged in user
const Message = ({ text, username, date, self }) =>
(
  <div className={(self ? ' message-self' : 'message-other')}>
    <div className='message-text'>{text}</div>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='message-username'>{username}</div>
      <div className='message-date'>{Datetime.formatFullDate(date)}</div>
    </div>
  </div>
)

export default Message;
