import {Chat, Classy, Component, Email, Data, Shop, Social, User} from '../interact';
import ApiRequest  from '../api/request.js';

/**
  This will handle all API calls so they are not randomly made throughout the application
*/
export default class ApiManager
{
    static #singleton = null;
    #apiToken = null;


    // Singleton
    /**
      Initializer so we can get an API token
      @returns {ApiManager}
     */
    static Init(apiToken = null)
    {
      // Initialize and load styles
      if(ApiManager.singleton == null)
      {
        ApiManager.#singleton = new ApiManager();
        console.log('\t\tApiManager.Init()');
      }
      if(apiToken !== null)
      {
        ApiManager.#singleton.#apiToken = apiToken;
      }
      return ApiManager.#singleton;
    }

    /**
      Interact with a route (social|data) and perform action (like|report|create|delete|update)
      @note   Params come in as params object
      @param  {String}  route   (social|data)
      @param  {String}  action  (like|dislike) (create|delete|update)
      @param  {JSON}  params  Params of request
    */
    static async SendRequest(params)
    {
      if(!ApiManager.#singleton)
      {
        console.error('\t\tApiManager.SendRequest() attempted before ApiManager instantiated');
      }
      if(params.route === 'chat')
      {
        return Chat.Interact(params, ApiManager.#singleton.#apiToken);
      }
      if(params.route === 'classy')
      {
        return Classy.Interact(params, ApiManager.#singleton.#apiToken);
      }
      if(params.route === 'component')
      {
        return Component.Interact(params, ApiManager.#singleton.#apiToken);
      }
      if(params.route === 'data')
      {
        return Data.Interact(params, ApiManager.#singleton.#apiToken);
      }
      if(params.route === 'email')
      {
        return Email.Interact(params, ApiManager.#singleton.#apiToken);
      }
      else if(params.route === 'shop' && (params.action === 'add-product' || params.action === 'update-product'))
      {
        return Shop.Interact(params, ApiManager.#singleton.#apiToken);
      }
      else if(params.route === 'social')
      {
        return Social.Interact(params, ApiManager.#singleton.#apiToken);
      }
      else if(params.route === 'user')
      {
        return User.Interact(params, ApiManager.#singleton.#apiToken);
      }
      else
      {
        try
        {
          let response = await ApiRequest.sendRequest("post",
                                                      params.params,
                                                      params.route + '/' + params.action,
                                                      ApiManager.#singleton.#apiToken);
          return response.data;
        }
        catch(err)
        {
          return {error: err.message};
        }
      }
    }
}
