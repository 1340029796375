const symbols = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-".split("");

/**
	Reverse a string
	@param 	{String}	str 	String to reverse
	@returns {String}	Reversed string
*/
function reverse(str)
{
  return str.split("").reverse().join("");
}

/**
	Convert a number to base
	@param 	{Number}	num 	Number to convert
	@param 	{Int}			base 	base value to convert it to
	@returns {Number}	Converted number
*/
function toBase(num, base)
{
    let decimal = num;
    let temp = -1;
    let conversion = "";

    if (base > symbols.length || base <= 1)
		{
        throw new RangeError("Radix must be less than " + symbols.length + " and greater than 1");
    }

    while (decimal > 0)
		{
        temp = Math.floor(decimal / base);
        conversion = symbols[(decimal - (base * temp))] + conversion;
        decimal = temp;
    }

    return conversion;
}

function extractDocumentFromPointer(iFieldName, iRow)
{
  //console.log(iFieldName);
  var fieldName = iFieldName;
  var fieldNameInPtr = "";
  var row = iRow;

  // Get total pointers in key
  let occurrences = (fieldName.match(/\./g) || []).length;
  if(occurrences === 0)
  {
    if(iRow[iFieldName] === undefined)
    {
      return '';
    }
    else
    {
      return iRow;
    }
  }

  // Iterate all pointers
  var splitIndex = -1;
  for(var i = 0; i < occurrences; i++)
  {
    splitIndex = fieldName.indexOf('.');
    try
    {
      // Extract pointer
      fieldNameInPtr  = fieldName.substring(splitIndex + 1);
      fieldName = fieldName.substring(0, splitIndex);

      // Slowly parse down the data
      if(fieldNameInPtr.indexOf('.') !== -1)
      {
        row = row[fieldName];
      }
      else
      {
        return row[fieldName];
      }
      fieldName = fieldNameInPtr;
    }
    catch(err)
    {
      console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
      return "";
    }
  }
}

/**
		Convert long mongo DB object ID to short version for url usage
		@param 	{MongoDocument}	record 	Record to pull values from
    @param  {String}  pointerId     The ID in the record to pull from
		@returns {String}		Short version
*/
function getShortId({ record, pointerId })
{
	try
	{
    const doc = extractDocumentFromPointer(pointerId, record);
    const longId = doc._id;
    const createdOn = doc.createdOn;

		let shortId = '';

		// Convert hex to int and only use last 3 digits
		let counter = parseInt(longId.toString(16).slice(-6), 16);
		counter = parseInt(counter.toString().slice(-3), 10);

		// Add counter to timestamp so we have some variation on the time
		let time = new Date(createdOn).getTime();
		time += counter;

		// Convert to base 64 for url usage
		shortId = toBase(time, 64);
		/* Slice off the first, least variating, character
	  	this lowers the entropy, but brings us to 6 characters, which is nice.
	   	This will cause a roll-over once every two years, but the counter and the rest of the timestamp should make it unique (enough)
			*/
		shortId = shortId.slice(1);

		// Reverse so that the first 2 characters have most variation
		shortId = reverse(shortId);
		return shortId;
	}
	catch(err)
	{
		console.log(err.stack);
	}
}

export default {
  getShortId: getShortId
};
