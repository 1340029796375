import React from 'react';
import StyledComponent from 'styled-components';
import {
  CCol,
  CRow,

  CCard,
  CCardBody,
  CCardHeader,

  CForm,
  CFormGroup,
  CSelect,
  CLabel,
  CInputGroup,
  CInputGroupAppend,
  CInput,

  CButton,
} from '@coreui/react';

import Layout1 from './layout-1';
import { DataManager } from '../../managers';
import { ThirdPartySharingCommand } from '../../command/user';

const COMPONENT_NAME = 'sharing';

/**
  Allow user to opt in/out of sharing
*/
export default class Sharing extends React.Component
{
	_isMounted = false;
  _dataMgr = null;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);
		console.log('Sharing()');

    this.state =
    {
      isLoading: false,
      thirdPartySharing: null,
      userId: '',
      dataVersion: 0, // Allow data manager to update us using this
    };

    this._dataMgr = DataManager.GetInstance();
	}

	componentDidMount()
	{
    console.log('Sharing.componentDidMount()');
    this._isMounted = true;

    this.loadData();

    // Setup data manager listener to detect when app changes
    this._dataMgr.addObserver(COMPONENT_NAME,
    () =>
    {
      this.setState({ dataVersion: this.state.dataVersion + 1 });
    },
    COMPONENT_NAME);
	}
	componentWillUnmount()
	{
		this._isMounted = false;
    this._dataMgr.removeObserver(COMPONENT_NAME);
	}


  // MARK: - API
  loadData = async() =>
  {
    try
    {
      console.log(this.props);
      const splitUrl = this.props.location.search.split('=');
      if(splitUrl.length === 2)
      {
        const userId = splitUrl[1];
        if(userId)
        {
          const thirdPartySharing = await this._dataMgr.execute(await new ThirdPartySharingCommand(
          {
            setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
            userId: userId,
          }));

          this.setState({
            thirdPartySharing: thirdPartySharing,
            userId: userId,
          });
        }
      }
    }
    catch(err)
    {
      console.log(err);
    }
  }

  updateData = async() =>
  {
    await this._dataMgr.execute(await new ThirdPartySharingCommand(
    {
      setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
      userId: this.state.userId,
      thirdPartySharing: this.state.thirdPartySharing,
    }));
    this.props.showAlert(true, 'Success', `Email preferences updated`, 'success');
  }


	// MARK: - Render

  render()
	{
		console.log('Sharing.render()');

		return (
        <Layout1
          isLoading={this.state.isLoading}
          thirdPartySharing={this.state.thirdPartySharing}
          updateData={this.updateData}
          setProperty={(value) => {
            this.setState({ thirdPartySharing: value });
          }}
        />
		);
	}
}

const _css = StyledComponent.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .edit-top-actions
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .authnet-form
  {
    height: 600px;
  }

  #iframeAuthorizeNet
  {
    height: 600px;
  }

  .message
  {
    text-align: center;
    color: black;
  }
  .
`;
