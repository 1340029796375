import { parse } from 'query-string';
import React, { useEffect } from 'react';
import * as R from 'ramda';

import {
  CCol,
  CRow,
  CFormText,
  CInput,
} from '@coreui/react';
import Form from 'react-bootstrap/Form'
import { Link }  from 'react-router-dom';
import Row from 'react-bootstrap/Row';

import { Input } from '../input';
import { AppText, Images, States } from '../../../constant';

const Billing = ({
  useExistingProfileVisible,
  billingInfo,
  setBillingProperty,
  useExitingProfileOnClick,
}) =>
{
  console.log(useExistingProfileVisible);
  return (
    <div>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={6}>
          <div
            htmlFor="firstName"
            mr={2}
          >
            {'First Name'}
          </div>
          <CInput
            id="firstName"
            onChange={(e) => setBillingProperty('firstName', e.target.value)}
            size="256"
            type="text"
            value={billingInfo.firstName}
            autoComplete='given-name'
          />
        </CCol>
        <CCol md={6}>
          <div
            htmlFor="lastName"
            mr={2}
          >
            {'Last Name'}
          </div>
          <CInput
            id="lastName"
            onChange={(e) => setBillingProperty('lastName', e.target.value)}
            size="256"
            type="text"
            value={billingInfo.lastName}
            autoComplete='family-name'
          />
        </CCol>
      </Row>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={6}>
          <div
            htmlFor="state"
            mr={2}
          >
            {'State'}
          </div>
          <Form.Select
            aria-label="State"
            id="state"
            autoComplete='address-level1'
            onChange={(e) => setBillingProperty('state', e.target.value)}
            value={billingInfo.state}
          >
            {States &&
            States.map( (state, i) => {
              return (
                <option
                  key={`state-${i}-${state.abbreviation}`}
                  value={state.abbreviation}
                >{state.name}</option>)
            })}
          </Form.Select>
        </CCol>
        <CCol md={6}>
          <div
            htmlFor="city"
            mr={2}
          >
            {'City'}
          </div>
          <CInput
            id="city"
            onChange={(e) => setBillingProperty('city', e.target.value)}
            size="64"
            type="text"
            value={billingInfo.city}
          />
        </CCol>
      </Row>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={12}>
          <div
            htmlFor="address"
            mr={2}
          >
            {'Address'}
          </div>
          <CInput
            id="address"
            onChange={(e) => setBillingProperty('address', e.target.value)}
            size="128"
            type="text"
            value={billingInfo.address}
          />
        </CCol>
      </Row>
      <Row style={{marginBottom: '15px' }}>
        <CCol md={6}>
          <div
            htmlFor="zip"
            mr={2}
          >
            {'Zip Code'}
          </div>
          <CInput
            id="zip"
            onChange={(e) => setBillingProperty('zip', e.target.value)}
            size="5"
            max={20}
            type="text"
            value={billingInfo.zip}
          />
        </CCol>
        <CCol md={6}>
          <div
            htmlFor="phone"
            mr={2}
          >
            {'Phone Number'}
          </div>
          <CInput
            id="phone"
            onChange={(e) => setBillingProperty('phone', e.target.value)}
            size="10"
            type="number"
            value={billingInfo.phone}
          />
        </CCol>
      </Row>
      {useExistingProfileVisible &&
      <Row>
        <CCol md={6} />
        <CCol md={6}>
          <a
            className="link"
            onClick={useExitingProfileOnClick}
          >
            {'Use an existing credit card'}
          </a>
        </CCol>
      </Row>}
    </div>
  );
}

export default Billing;
