import React from 'react';
import StyledComponent from 'styled-components';
import {
  CCol,
  CRow,

  CFormFloating,
  CFormSelect,
  CForm,
  CFormGroup,
  CSelect,
  CLabel,
  CFormText,
  CInputGroup,
  CInputGroupAppend,
  CInput,

  CButton,
} from '@coreui/react';
import Card from 'react-bootstrap/Card'

import { FormLoader } from '../../components/Form';
import { DataManager } from '../../managers';
import {  FormTokenCommand, ProcessPaymentCommand } from '../../command/authnet';
import { AppText, Images } from '../../constant';
import { AcceptHosted, AcceptSelfHosted } from "../../components/authorizeNet";
import ProfileSelect from './profileSelect';
import SubscriptionSummary from './subscriptionSummary';
import LoanSummary from './loanSummary';
import { Datetime } from '../../utils';

const COMPONENT_NAME = 'pay';
const DATA_SET_ID = 'authnet';

// If false then we use Accept JS hosted form, otherwise our own
const USE_IN_HOUSE_FORM = true;

/**
  Allow user to pay
*/
export default class Pay extends React.Component
{
	_isMounted = false;
  _dataMgr = null;
  _acceptSelfHostedRef = null;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);
		console.log('Pay()');

    this.state =
    {
      isLoading: false,
      receiptId: null,
      usingNewProfile: false,
      cvv: '',
      selectedProfile: '',
      canPay: false,
      dataVersion: 0, // Allow data manager to update us using this
    };

    this._acceptSelfHostedRef = React.createRef()

    this._dataMgr = DataManager.GetInstance();
	}

	componentDidMount()
	{
    console.log('Pay.componentDidMount()');
    this._isMounted = true;

    this.loadData();

    // Setup data manager listener to detect when app changes
    this._dataMgr.addObserver(COMPONENT_NAME,
    () =>
    {
      this.setState({ dataVersion: this.state.dataVersion + 1 });
    },
    COMPONENT_NAME);
	}
	componentWillUnmount()
	{
		this._isMounted = false;
    this._dataMgr.removeObserver(COMPONENT_NAME);
	}


  // MARK: - API
  loadData = async() =>
  {
    try
    {
      console.log(this.props);
      const splitUrl = this.props.location.search.split('=');
      if(splitUrl.length === 2)
      {
        const receiptId = splitUrl[1];
        console.log(receiptId);
        if(receiptId)
        {
          this.setState({ receiptId: receiptId });
          const data = await this._dataMgr.execute(await new FormTokenCommand(
          {
            setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
            receiptId: receiptId,
            origin: window.location.origin,
            inHouseForm: USE_IN_HOUSE_FORM,
          }));

          // If payment profile default to first
          if(data &&
            data.paymentProfiles &&
            data.paymentProfiles.length > 0)
          {
            this.setState({ selectedProfile: data.paymentProfiles[0].customerPaymentProfileId });
          }
          // Otherwise show add new profile screen
          else
          {
            this.setState({ usingNewProfile: true });
          }
        }
      }
    }
    catch(err)
    {
      console.log(err);
    }
  }

  processPayment = async(externalId) =>
  {
    try
    {
      let paymentProfileId = '';
      let opaqueData = '';

      if(this.state.usingNewProfile)
      {
        opaqueData = await this._acceptSelfHostedRef.current.submitProfile();
      }
      else
      {
        paymentProfileId = this.state.selectedProfile;
      }
      console.log(paymentProfileId);
      const result = await this._dataMgr.execute(await new ProcessPaymentCommand(
      {
        setIsLoading: (isLoading) => this.setState({ isLoading: isLoading }),
        receiptId: this.state.receiptId,
        paymentProfileId,
        cvv: this.state.cvv,
        opaqueData,
        externalId,
        billingInfo: this._acceptSelfHostedRef.current ? this._acceptSelfHostedRef.current.getBillingInfo() : {},
      }));

      if(!result)
      {
        // Tell accept hosted to reset validation on cc if paying with new profile
      }
    }
    catch(err)
    {
      console.error(err);
      this.props.showAlert(
        true,
        'Error',
        err.messages ? err.messages.message.map((err: any) => err.text) : 'An error has occurred, please contact support',
        'danger');
    }
  }


	// MARK: - Render

  render()
	{
		console.log('Pay.render()');
    console.log('Can Pay: ' + this.state.canPay);
    console.log('Using new profile: ' + this.state.usingNewProfile);

    const dataSet = this._dataMgr.getData(DATA_SET_ID);
    const data = dataSet ? dataSet[this.state.receiptId] : null;

    const canPay = this.state.usingNewProfile ?
      (this.state.canPay) :
      (this.state.selectedProfile && this.state.cvv.length > 2);

		return (
      <_css>
        {(!this.props.cookies || this.props.cookies.get('user')) &&
        <img
          className="c-sidebar-brand-full"
          style={{height: '35px', marginBottom: '7px'}}
          src={Images['bgIcon']}
        />}

        <FormLoader
          isLoading={this.state.isLoading}
          key={`payments-loader`}
        />

        {/* Messages */}
        {data &&
        data.receipt &&
        data.receipt.status === "active" &&
        <p className={'message'}>Thank you for your payment!</p>}

        {(!data || !data.receipt) &&
        !this.state.isLoading &&
        <p className={'message'}>Invalid payment page. Please contact support</p>}

        {data &&
        data.receipt &&
        data.receipt.status === 'inactive' &&
        <p className={'message'}>Payment is not required, this purchase was voided.</p>}

        {data &&
        data.receipt &&
        data.receipt.status === 'pending-payment' &&
        data.formToken &&
        <CRow>
          <CCol
            md={8}
          >
            {/* Payment profiles and add profile */}
            <Card style={{ padding: '20px' }}>

              <h5>{AppText.pay.paymentInfo}</h5>
              <>
                {this.state.usingNewProfile
                ?
                  (USE_IN_HOUSE_FORM
                  ?
                  <AcceptSelfHosted
                    ref={this._acceptSelfHostedRef}
                    formToken={data.formToken}
                    mode={data.mode}
                    apiLoginId={data.apiLoginId}
                    clientKey={data.publicKey}
                    useExitingProfileOnClick={() => this.setState({ usingNewProfile: false })}
                    useExistingProfileVisible={data.paymentProfiles && data.paymentProfiles.length > 0}
                    useNewProfileOnClick={() => this.setState({ usingNewProfile: true })}
                    billingInfo={this.state.billingInfo}
                    setBillingProperty={(prop, val) => {
                      const billingInfo = {...this.state.billingInfo};
                      billingInfo[prop] = val;
                      this.setState({ billingInfo: billingInfo })
                    }}
                    onValidChange={(isValid) => {
                      console.log('setting canPay to ' + isValid);
                      this.setState({ canPay: isValid });
                    }}
                  />
                  :
                  <AcceptHosted
                    formToken={data.formToken}
                    mode={data.mode}
                    type={'iframe'}
                    visible={this.state.usingNewProfile}
                    isPaymentPage={data.isPaymentPage}
                    useExistingProfileVisible={data.paymentProfiles && data.paymentProfiles.length > 0}
                    onTransact={(response) =>
                    {
                      this.processPayment(response.transId);
                    }}
                  />)
                :
                <ProfileSelect
                  paymentProfiles={data.paymentProfiles}
                  useNewProfileOnClick={() => this.setState({ usingNewProfile: true })}
                  cvv={this.state.cvv}
                  cvvOnChange={(field, evt) =>
                  {
                    this.setState({ cvv: evt.target.value });
                    return evt;
                  }}
                  selectedProfile={this.state.selectedProfile}
                  selectedProfileOnChange={(profile) => this.setState({ selectedProfile: profile })}
                />}
              </>
            </Card>
          </CCol>

          {/* Summary and pay button */}
          <CCol
            md={4}
          >
            {data.receipt.productSubscription ?
            <SubscriptionSummary
              price={data.receipt.productSubscription.price}
              summary={`${data.receipt.productSubscription.product === 'ads'
                ? 'Ad position '
                : data.receipt.productSubscription.product} ${data.receipt.productSubscription.quantity}`}
              chargeText={`The charge on your credit card will appear as ${this.props.cookies.get('app') === 'bg' ? 'Business Generator' : 'Deposit Flow'}`}
              renderPayButton={() =>
                <CButton
                  color="success"
                  disabled={!canPay}
                  onClick={() => this.processPayment('')}
                >Pay</CButton>
              }
            /> :
            <LoanSummary
              price={data.receipt.loan.amount}
              summary={`Loan dispursed on ${Datetime.formatDateOnly(data.receipt.createdOn)}`}
              chargeText={`The charge on your credit card will appear as ${this.props.cookies.get('app') === 'bg' ? 'Business Generator' : 'Deposit Flow'}`}
              renderPayButton={() =>
                <CButton
                  color="success"
                  disabled={!canPay}
                  onClick={() => this.processPayment('')}
                >Pay</CButton>
              }
            />}
          </CCol>
        </CRow>}
      </_css>
		);
	}
}

const _css = StyledComponent.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .edit-top-actions
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .authnet-form
  {
    height: 600px;
  }

  #iframeAuthorizeNet
  {
    height: 640px;
  }

  .message
  {
    text-align: center;
    color: black;
  }
  .link
  {
    text-decoration: none;
    font-weight: 500 !important;
    color: #007bff !important;
    cursor: pointer;
  }
  .
`;
