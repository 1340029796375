import React from 'react'
import StyledComponent from 'styled-components';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import ChatIcon from '@material-ui/icons/Chat';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';

import CloseIcon from '@material-ui/icons/Close';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { toast } from "react-toastify";

import { NotificationUtility } from '../notifications';
import { Conversation, Header, Message, UserAutocomplete } from '.';
import AppText from '../../constant/text';
import { ApiManager, NotificationManager } from '../../managers';

// The message window contains all the messages
export default class Container extends React.Component
{
  // Used to autoscroll as messages are received
  _containerRef = null;
  _css = null;
  _isMounted = false;
  _sentinal = '/messages/';

  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tMessages()");
    super(props)

    // Check if we are viewing this in context of a particular conversation
    // ID will be in query string
    const conversationId = props.location.pathname.substr(this._sentinal.length);

    this.state =
    {
      textInput: '',
      startingConversationWithUser: false,
      messages: [],
      conversations: [],
      activeConversation: conversationId,
      notificationManager: NotificationManager.GetInstance()
    };

    this.state.notificationManager.addObserver(this);

    this._containerRef = React.createRef()

    // Style component
    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('\tMessages.componentDidMount()');
    this._isMounted = true;

    await this.loadData();
  }

  // MARK: - API related
  /**

  */
  loadData = async () =>
	{
		try
		{
			console.log('Messages.loadData()');
			this.props.updateMasterState({ isLoading: true });
			const response = await ApiManager.SendRequest(
			{
				route: 'data',
				action: 'query',
        model: 'conversation',
        params:
        {
          users: this.props.user._id.toString()
        }
			});
			if(response.error !== null)
			{
				this.props.updateMasterState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}

      // If viewing in context of a particular message then we need to set that conversation active
      if(this.state.activeConversation)
      {
        for(let i = 0; i < response.results.length; i++)
        {
          if(response.results[i]._id.toString() === this.state.activeConversation)
          {
            await this.loadConversation(response.results[i]);
            break;
          }
        }
      }

      this.props.updateMasterState({ isLoading: false });
			this.setState({	conversations: response.results });
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  loadConversation = async(conversation) =>
  {
    try
		{
			console.log('Messages.loadData()');
			this.props.updateMasterState({ isLoading: true });
			const response = await ApiManager.SendRequest(
			{
				route: 'data',
				action: 'query',
        model: 'message',
        params:
        {
          conversation: conversation._id.toString()
        }
			});
			if(response.error !== null)
			{
				this.props.updateMasterState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}

      this.props.updateMasterState({ isLoading: false });

      // Figure out who the conversation is with
      let startingConversationWithUser = conversation.createdBy;
      if(startingConversationWithUser._id.toString() === this.props.user._id.toString())
      {
        for(let i = 0; i < conversation.users.length; i++)
        {
          if(conversation.users[i]._id.toString() !== this.props.user._id.toString())
          {
            startingConversationWithUser = conversation.users[i];
            break;
          }
        }
      }

			this.setState(
      {
        activeConversation: conversation,
        messages: response.results,
        startingConversationWithUser: startingConversationWithUser
      });
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
  }

  loadMessageFromNotification = async(notification) =>
  {
    try
		{
			console.log('Messages.loadMessage()');
			this.props.updateMasterState({ isLoading: true });
			const response = await ApiManager.SendRequest(
			{
				route: 'data',
				action: 'query',
        model: 'message',
        params:
        {
          _id: notification.entityId
        }
			});
			if(response.error !== null)
			{
				this.props.updateMasterState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}

      this.props.updateMasterState({ isLoading: false });

      if(response.results.length === 0)
      {
        this.props.showAlert(true, 'Un-oh', 'Could not locate notification', 'danger');
				return;
      }

      // If we are already on this conversation just append the message
      if(this.state.activeConversation &&
        this.state.activeConversation._id.toString() === response.results[0].conversation)
      {
        const messages = [...this.state.messages];
        messages.push(response.results[0]);
        this.setState({ messages: messages });
      }

      else
      {
        // Otherwise show notification
        this.toastNotification(notification);
      }
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
  }

  /**
    Send a message
  */
  sendMessage = async() =>
  {
    this.props.updateMasterState({ isLoading: true });
    const response = await ApiManager.SendRequest(
    {
      route: 'chat',
      action: 'message',
      params:
      {
        text: this.state.textInput,
        recipient: this.state.startingConversationWithUser._id.toString()
      }
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // If we started a conversation with this message
    // we need to add the conversation
    const conversations = [...this.state.conversations];
    if(!this.state.activeConversation)
    {
      conversations.push(response.results.conversation);
    }

    const messages = [...this.state.messages];
    messages.push(response.results);
    this.setState(
    {
      messages: messages,
      textInput: '',
      conversations: conversations,
      activeConversation: (this.state.activeConversation ? this.state.activeConversation : response.results.conversation)
    });
    this.props.updateMasterState({ isLoading: false });
  }

  // MARK: - Notification manager observer related
  newNotification = (notification) =>
  {
    console.log('\tMessages.newNotification()');
    console.log(notification);

    // If it's a new message let's check for which conversation
    if(notification.entityType === 'message')
    {
      this.loadMessageFromNotification(notification);
    }
    // Otherwise display it
    else
    {
      this.toastNotification(notification);
    }
  }

  toastNotification(notification)
  {
    toast(NotificationUtility.parseNotificationBody(notification),
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: () =>
      {
        const forwardUrl = '/view/notification/' + NotificationUtility.base64EncodeUnicode(notification._id.toString()) + '/';
        this.props.history.push(forwardUrl);
      }
    });
  }

  // MARK: - Render
  shouldComponentUpdate(nextProps, nextState)
  {
    // TODO: Make text input inside component and we'll use a ref to access it
    return (
      nextState.startingConversationWithUser !== this.state.startingConversationWithUser ||
      nextState.messages !== this.state.messages ||
      nextState.conversations !== this.state.conversations ||
      nextState.textInput !== this.state.textInput
    );
  }

  componentDidUpdate()
  {
    // Everytime the component updates (when a new message is sent) we
    // change the `scrollTop` attribute to autoscroll the message window
    // to the bottom
    const messageWindow = this._containerRef.current
    messageWindow.scrollTop = messageWindow.scrollHeight - messageWindow.clientHeight
  }

  render()
  {
    console.log('\tMessages.render()');

    let headerText = AppText.messages.header.description;
    if(this.state.startingConversationWithUser && !this.state.activeConversation)
    {
      headerText += ' with ' + this.state.startingConversationWithUser.username;
    }
    else if(this.state.activeConversation && this.state.activeConversation.users)
    {
      for(let i = 0; i < this.state.activeConversation.users.length; i++)
      {
        console.log(this.state.activeConversation.users[i]._id.toString());
        if(this.state.activeConversation.users[i]._id.toString() !== this.props.user._id.toString())
        {
          headerText = 'Conversation with ' + this.state.activeConversation.users[i].username;
          break;
        }
      }
    }

    // The message window is a container for the list of messages, which
    // as mapped to `Message` components
    return (

      <this._css
        ref={this._containerRef}
      >
        <div className='messages-top'>
          {this.state.startingConversationWithUser &&
          <IconButton
            aria-label="Close conversation"
            className={'messages-close-btn'}
            onClick={() => this.setState(
            {
              startingConversationWithUser: false,
              messages: [],
              activeConversation: false
            })}
            edge="end"
          >
            <CloseIcon />
          </IconButton>}
          <Header
            key='messages-header'
            title={AppText.messages.header.title}
            description={headerText}
            siteManager={this.props.siteManager}
          />
        </div>

        {!this.state.startingConversationWithUser &&
          this.state.messages.length === 0 &&
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <UserAutocomplete
              onChange={(val) => this.setState({ startingConversationWithUser: val })}
              showAlert={this.props.showAlert}
              siteManager={this.props.siteManager}
              user={this.props.user}
            />
          </div>
          {false &&
          <div className='col-lg-4 col-md-4 col-sm-5 col-xs-4 start-conversation-container'>
            <Button
              variant="contained"
              color="primary"
              className={'start-conversation-btn'}
              onClick={() =>
              {
                /*this.setState({ startingConversation: true })*/
              }}
              endIcon={<ChatIcon>start</ChatIcon>}
            >{AppText.messages.autocomplete.send}</Button>
          </div>}
        </div>}

        {this.state.messages.length === 0 &&
        !this.state.startingConversationWithUser &&
        <div>
          <Typography
            variant="h6"
            className={'conversations-title'}
            display='block'
            align={'left'}
          >{this.state.conversations.length > 0 ? AppText.messages.conversations.title : AppText.messages.conversations.titleEmpty}</Typography>
            <div className='conversations'>
            {this.state.conversations.length > 0 && this.state.conversations.map( (conversation, i) =>
            {
              console.log(conversation);
              console.log(this.props.user);
              const otherUser = conversation.users.filter(user => user._id.toString() !== this.props.user._id.toString())[0];

              return (
                <Conversation
                  key={`messages-conversation-${i}`}
                  conversation={conversation}
                  otherUser={otherUser}
                  updateMasterState={this.props.updateMasterState}
                  loadConversation={this.loadConversation}
                />
              );
            })}
          </div>
        </div>}

        <div
          className='messages-container'
          ref={this._containerRef}
        >
          {this.state.messages.length > 0 &&
          this.state.messages.map((msg, i) =>
          {
            return (
              <Message
                key={i}
                text={msg.text}
                username={msg.createdBy.username}
                self={this.props.user._id.toString() === msg.createdBy._id.toString()}
              />
            )
          })}
        </div>

        <Slide
          direction="up"
          in={this.state.messages.length > 0 || this.state.startingConversationWithUser ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <div className='row message-input-row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <OutlinedInput
                id="message-input"
                fullWidth={true}
                type={'text'}
                value={this.state.textInput}
                placeholder={"What's on your mind?"}
                onKeyPress={(e) =>
                {
                  if(e.key === 'enter')
                  {
                    this.sendMessage();
                  }
                }}
                onChange={(e) => this.setState({ textInput: e.target.value }) }
                endAdornment=
                {
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.sendMessage}
                      edge="end"
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </Slide>
      </this._css>
    )
  }

  // Style component
  styleComponent = (siteManager) =>
  {
  //  let btnColor = siteManager.getColorFor('Form', 'Submit Button');
    return StyledComponent.div`
      min-height: 80%;
      overflow-y: scroll;
      padding: 15px 15px;

      /*.messages-container
      {
        height: ${window.innerHeight * 0.75}px;
        overflow-y: scroll;
      }*/

      .message
      {
        justify-content: flex-start;
        width: 80%;
        background-color: #f5fbef;
        border: 1px solid #c7e2c9;
        padding: 4px;
        border-radius: 4px;
        margin: 5px 0;
        display: grid;
      }
      .message-input-row
      {
        margin-top: 50px;
        display: block;
      }
      .message-self
      {
        background-color: #d1beb0;
        border: 1px solid #c49f94;
        justify-content: flex-end;
        float: right;
      }

      .message-username
      {
        font-size: 12px;
        font-weight: bold;
        color: #5a352a;
      }

      .message-text
      {
        font-size: 15px;
      }


      .textbar
      {
        display: flex;
        align-items: stretch;
      }

      .textbar-input
      {
        flex-grow: 1;
        box-sizing: border-box;
      }

      .textbar-send
      {
        border-radius: 0;
        border: none;
      }

      .userautocomplete-container
      {

      }
      .start-conversation-container
      {
        @media only screen and (max-width: 991px) and (min-width: 238px)
  			{
  				margin: auto;
  			}
      }
      .start-conversation-btn
      {
        height: 100%;
        width: 100%;
        background-color: #70a040;
      }
      .conversation-card-actions
      {
        padding: 0px !important;
        margin: 8px;
      }
      .messages-close-btn
      {
        display: flex;
      }
      .conversation-avatar
      {
        width: 100%;
        height: 80px;
        display: flex;
        overflow: hidden;
        position: relative;
        font-size: 1.25rem;
        align-items: center;
        flex-shrink: 0;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        user-select: none;
        border-radius: 0px !important;
        justify-content: center;
        border: 3px solid #cecfd1;
        border-radius: 5px !important;
        margin: auto;
      }
      .conversation-card
      {
        width: 160px;
        height: 180px;
        margin-right: 15px;
      }
      .conversation-name
      {
        color: #225373;
        font-weight: 600;
        line-height: 1.1;
        font-size: 1vw;

        @media(max-width: 1199px)
        {
          font-size: 15px;
        }
      }
      .conversation-card-content
      {
        padding: 0px !important;
        margin: 12px;
      }
      .view-conversation-btn
      {
        width: 100%;
      }
      .conversations-title
      {
        margin-top: 50px;
        margin-bottom: 10px;
      }
      .conversations
      {
        display: flex;
      }
      .messages-top
      {
        display: flex;
      }
    `;
  }
}
