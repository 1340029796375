import { parse } from 'query-string';
import React, { useEffect, useRef } from 'react';

import {
  CButton,
} from '@coreui/react';

function isValidMessage(o)
{
  return typeof o === 'object' && o !== null;
}

function isValidTransaction(o)
{
  return o !== null && o !== undefined && typeof o === 'object';
}

const AuthorizeNetPostUrlPayment =
{
  production: 'https://accept.authorize.net/payment/payment',
  sandbox: 'https://test.authorize.net/payment/payment'
};

const AuthorizeNetPostUrlProfile =
{
  production: 'https://accept.authorize.net/customer/addPayment',
  sandbox: 'https://test.authorize.net/customer/addPayment',
};

export const AcceptHosted = ({
  formToken,
  isPaymentPage, // If false we display profile page
  mode = 'sandbox',
  type = 'iframe',
  onMessage,
  style,
  className,
  children,
  onReceiveCommunication,
  onCancel,
  visible,
  onResize,
  onTransact
}) =>
{
  console.log('AcceptHosted');

  useEffect(() =>
  {
    console.log(formRef.current);
    formRef.current.submit();
  }, [formToken]);

  useEffect(() =>
  {
    window.AuthorizeNetIFrame =
    {
      onReceiveCommunication: (queryStr) =>
      {
        console.log(queryStr);
        onReceiveCommunication && onReceiveCommunication(queryStr)

        const message = parse(queryStr)
        if (isValidMessage(message)) {
          onMessage && onMessage(message)

          switch (message.action) {
            case 'transactResponse':
              const json = JSON.parse(message.response)
              if (isValidTransaction(json)) {
                onTransact && onTransact(json)
              }
              break

            case 'resizeWindow':
              onResize && onResize(message.width, message.height)
              break

            case 'cancel':
              onCancel && onCancel();
              break
          }
        }
      }
    }
    return () => {
      console.log('Removing callback');
      delete window.AuthorizeNetIFrame
    }
  }, []);

  const formRef = useRef(null);

  return (
    <div style={style} className={className}>
      <form
        ref={formRef}
        onSubmit={() => console.log('Submitted' + formToken)}
        method={'POST'}
        action={isPaymentPage ? AuthorizeNetPostUrlPayment[mode] : AuthorizeNetPostUrlProfile[mode]}
        target={'iframeAuthorizeNet'}
      >
        <input name="token" type={'hidden'} value={formToken} />
      </form>

      {/* IFrame to auth net */}
      <iframe
        id={'iframeAuthorizeNet'}
        name={'iframeAuthorizeNet'}
        frameBorder={0}
        width={'100%'}
        style={{ display: visible ? 'block' : 'none' }}
      />
    </div>
  );
}

export default AcceptHosted
