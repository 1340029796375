import { ApiManager } from '../../managers';
import { Command } from '..';

export async function CreateDataCommand({ setIsLoading, model, params, dataSetId, dataSetField })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tCreateCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'data',
        action: 'create',
        model: model,
        params: params
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      // Save new record in data set
      const dataSet = dataMgr.getData(dataSetId);
      if(dataSet)
      {
        if(Array.isArray(dataSet[dataSetField]))
        {
          dataSet[dataSetField].push(response.results);
        }
        else
        {
          dataSet[dataSetField] = [response.results];
        }
      }
      dataMgr.setData(dataSetId, dataSet);

      setIsLoading(false);

      // Tell observers data set has updated
      dataMgr.notifyObservers(dataSetId);

      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
