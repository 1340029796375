import React from 'react'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { NotificationUtility } from '../../components/notifications';

const Conversation = ({ conversation,
                        loadConversation,
                        otherUser,
                        updateMasterState }) =>
{
  return (
    <Card
      key={conversation._id.toString()}
      className='conversation-card'
    >
      <CardActionArea
      >
        <CardContent className='conversation-card-content'>
          <Avatar
            alt="Profile image"
            src={otherUser.photo ? otherUser.photo : require('../../asset/no-photo.png')}
            className={'conversation-avatar'}
            onClick={() =>
            {
              const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(otherUser._id.toString()) + '/';
              updateMasterState({ redirect: redirect })
            }}
          />
          <Typography gutterBottom variant="h5" component="h2" className={'conversation-name'}>
            {`${otherUser.firstName} ${otherUser.lastName}`}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className='conversation-card-actions'>
        <Button
          size="small"
          color="primary"
          className={'view-conversation-btn'}
          onClick={() =>
          {
            loadConversation(conversation);
          }}
        >View</Button>
      </CardActions>
    </Card>
  );
}
export default Conversation;
