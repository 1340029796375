import React, { lazy } from 'react'
import {
  CBadge,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CCallout
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { Counters } from './counters';
import { Tables } from './tables';
import { ActivityFeed } from './activityFeed';

const Layout1 = ({  isLoading,
                    widgets,
                    cookies,
                }) =>
{
  return (
    <>
      {widgets &&
      widgets.activityFeed &&
      <ActivityFeed
        activityFeed={widgets.activityFeed}
      />}
      {widgets &&
      widgets.counters &&
      <Counters
        counters={widgets.counters}
      />}
      {widgets &&
      widgets.tables &&
      <Tables
        tables={widgets.tables}
      />}
    </>
  )
}

export default Layout1;
